import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public initLang = 'es';

  constructor(public translateService: TranslateService) {
    translateService.addLangs([this.initLang, 'en']);
    translateService.setDefaultLang(this.initLang);
    translateService.use(this.initLang);
    // console.log('XXXXXXXXXXXXXXX', navigator.language);
  }

  title = 'app';
}
