// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  app: "VIKKON",
  api: "https://apivikkon-dev2027.herokuapp.com/api/v1/",
  apiLocation: "https://location-ms-dev.onrender.com/",
  functions:"https://us-central1-vikkon-dev2027.cloudfunctions.net/widgets/",
  firebase:{
    apiKey: "AIzaSyBK4Ft0RWcUGMWkG1AD--8E0WFF0Bi-cFk",
    authDomain: "vikkon-dev2027.firebaseapp.com",
    databaseURL: "https://vikkon-dev2027.firebaseio.com",
    projectId: "vikkon-dev2027",
    storageBucket: "vikkon-dev2027.appspot.com",
    messagingSenderId: "722632360579",
    appId: "1:722632360579:web:bfd39f76702469943951b6",
    measurementId: "G-D2CQPSX7RM"
  },
  mapboxKey : 'pk.eyJ1IjoibWFwYm94LXZpa2tvbiIsImEiOiJja2Jjc2oxdDAwNGo0MnRvY29sZm44cjBsIn0.gYLQz3heOEVh7PyaCD-rsw',
  appIdOnSignal: 'c845d14d-a4e1-4a10-b10e-7fc451099999',
  appIdOnSignalLocal: 'dfd4bdfe-14ac-4117-91d1-67a9e1b9cebe',
  maps:{
    assets:{
      webserviceUrl: "https://apivikkon-dev2027.herokuapp.com/api/v1",
      urlMap: "https://apivikkon-maps-dev2027.herokuapp.com"
    }
  }
};
