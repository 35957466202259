import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VikkonTable } from './vikkon-table.component';
// import { DataTablesModule } from 'angular-datatables';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { CovalentPagingModule } from '@covalent/core/paging';
import { MaterialModule } from './../../../material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CovalentSearchModule } from '@covalent/core/search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        CovalentDataTableModule,
        CovalentPagingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CovalentSearchModule,
        NgbModule
    ],
    declarations: [
        VikkonTable
    ],
    providers: [
        //ProductsService
    ],exports:[
        VikkonTable
    ]
})
export class VikkonTableModule { }