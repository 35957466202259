import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable()
export class MessageService {
    constructor(){}

    public success(title,text):Promise<any>{
        return Swal.fire({
            title: title,
            text: text,
            type: 'success',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        });
    }

    public successBold(title,txt1,value,tx3):Promise<any>{
        return Swal.fire({
            title: title,
            html: `${txt1} <b>${value}</b>,${tx3}`,
            type: 'success',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        });
    }

    public ToastSuccess(title,text):Promise<any>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });

        return Toast.fire({
            type: 'success',
            title: 'Signed in successfully'
        });
    }

    public ToastDanger(type,text):Promise<any>{
        const Toast = Swal.mixin({
            html:`<div style="color:#721C2B;margin-left:10px;font-size:15;">${text}</div>`,
            toast: true,
            position: 'top-end',
            background:"#f8d7da",
            showConfirmButton: false,
            timer: 5000
          });
        return Toast.fire({
            type: type,
            // title: text
        });
    }

    public ToastInfoTopEnd(text):Promise<any>{
        const Toast = Swal.mixin({
            html:`<div style="color:white;margin-left:10px;font-size:15;">${text}</div>`,
            background:"#222222",
            toast: true,
            position: 'bottom-right',
            showConfirmButton: false,
            timer: 5000
          });

        return Toast.fire({
            type: 'info'
        });
    }


    public errorToast(title: string, text: string, widthText?: number, timer?: number, imageUrl?: string, imageSize?: number): Promise<SweetAlertResult> {
        widthText = widthText ? widthText : 250;
        imageSize = imageSize ? imageSize : 45;

        const Toast = Swal.mixin({
            html: `
                <div style="width: ${ widthText - imageSize }px;">
                <div style="color:black; width: 100%;"> <H3>${ title }</H3></div>
                <div style="color:gray;margin-left:10px;padding-right:10px;font-size:15;width: 100%;">${ text }</div>
                </div>
            `,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: (timer ? timer : 5000)
        });

        let objImage = {};
        if ( imageUrl !== null ) {

            objImage = {
                imageUrl: (imageUrl ? imageUrl : 'assets/images/sad_face.png'),
                imageWidth: imageSize,
                imageHeight: imageSize
            };

        }
        return Toast.fire(objImage);
    }


    public error(title,text):Promise<any>{
        // return Swal.fire({
        //     title: title,
        //     text: text,
        //     type: 'error',
        //     confirmButtonText: 'Ok',
        //     allowEscapeKey:false,
        //     allowOutsideClick:false
        // })
        return Swal.fire({
            title: title,
            text: text,
            imageUrl: 'assets/images/sad_face.png',
            imageWidth: 90,
            imageHeight: 90,
            imageAlt: 'Custom image',
            // animation: true
            animation: false,
            customClass: {
                popup: 'animated tada'
            }
        })
    }

    public warning(title,text):Promise<any>{
        return Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        })
    }

    public info(title,text):Promise<any>{
        return Swal.fire({
            title: title,
            text: text,
            type: 'info',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        })
    }

    public question(title,text, labelConfirm: string = 'Aceptar', labelCancel: string = 'Cancelar'):Promise<any>{
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: title,
                text: text,
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#009933',
                cancelButtonColor: '#d33',
                confirmButtonText: labelConfirm,
                cancelButtonText: labelCancel
            }).then((action)=>{
                if(action.value){
                    resolve(true);
                }else{
                    resolve(false);
                }
            }).catch((err)=>{
                reject(err);
            })
        });
    }

    public customMsg(title: string, html: string, type: ('warning' | 'info' | 'error' | 'success')  ): Promise<any>{
        return Swal.fire({
            title: title,
            html: html,
            type: type,
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        })
    }

    public custom(config):Promise<any>{
        return Swal.fire(config);
    }

    // public validate(message){
    //     swal.showValidationError(message);
    // }
}
