export enum OrderType {
    ASC = 'asc',
    DESC = 'desc'
}

export interface RequestOptions {
    page?: number;
    limit?: number;
    sortBy?: string;
    filter?: string;
    order?: OrderType;
}