import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AuthService } from './shared/services/auth.service';
import { GuardService } from './shared/services/guard.service';
import { ValidatorService } from './shared/services/validator.service';
import { RequestService } from './shared/services/request.service';
import { HttpModule } from '@angular/http';
import { MessageService } from './shared/services/message.service';
import { PrivilegesService } from './shared/services/privileges.service';
import { FlotModule } from 'ng2modules-flot';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { VikkonTableModule } from './shared/modules/vikkon-table';

import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule  } from '@covalent/core/steps';
import { PrivilegesGuard } from './shared/services/privileges.guard';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    // BreadcrumbComponent,
    SidebarComponent,
    // RightSidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'web-cloud'),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    HttpModule,
    FlotModule,
    MaterialModule,
    // DataTablesModule,
    AngularFireStorageModule,
    VikkonTableModule,
    CovalentLayoutModule,
    CovalentStepsModule
  ],
  providers: [
    AuthService,
    GuardService,
    ValidatorService,
    RequestService,
    MessageService,
    PrivilegesService,
    PrivilegesGuard,
    AngularFirestoreModule
  ],
  // entryComponents:[SpinnerComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
