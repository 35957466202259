import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/startWith';
import { Observable } from 'rxjs/Observable';

import { TdPagingBarComponent, IPageChangeEvent } from '@covalent/core/paging';

import {
	TdDataTableComponent,
	ITdDataTableColumn,
	TdDataTableSortingOrder,
	TdDataTableService,
	ITdDataTableSortChangeEvent,
} from '@covalent/core/data-table';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'vikkon-table',
	templateUrl: './vikkon-table.template.html',
	styleUrls: ['./vikkon-table.style.css']
})
export class VikkonTable implements AfterViewInit, OnDestroy, OnInit  {
	@Input() data : VikkonTableResponse = {
		results : [],
		page : 0,
		pages : 0,
		count : 0
	};
	@Input() height;
	@Input() searchTerm : string = "";
	@Input() currentPage : number = 1;
	@Input() pages : number = 0;
	@Input() totalItems : number = 0;
	@Input() pageSize: number = 10;
	@Input() columns : [] = [];
	@Input() sortOrder : [] = [];
	@Input() sortBy : string;
	@Input() order: string;
	@Input() requestOptions: any;
	@Input() selectable: boolean = false;
	@Input() multiple: boolean = true;
	@Input() labelsTable: any = {
		headerLeft1: "Mostrar",
		headerLeft2: "registros",
		searchTitle: "buscar...",
		footerLeft1: "Mostrando página",
		footerLeft2: "de",
		footerLeft3: "elementos",
		footerRight1: "Página",
		footerRight2: "de"
	};
	

	@Output() onButtonClicked : EventEmitter<any> = new EventEmitter();
	@Output() onRowSelected : EventEmitter<any> = new EventEmitter();
	@Output() onPageChanged : EventEmitter<any> = new EventEmitter();
	@Output() onSorted : EventEmitter<any> = new EventEmitter();
	@Output() onSearch : EventEmitter<any> = new EventEmitter();
	@Output() onClean : EventEmitter<any> = new EventEmitter();
	@Input() loading : boolean = true;

	@ViewChild('dtTable') dtTable:TdDataTableComponent;
	// @ViewChild(TdPagingBarComponent, { read: true }) pagingBar: TdPagingBarComponent;

	@ViewChild('pagingBarLinks') pagingBarLinks:TdPagingBarComponent;
	
	form: FormGroup;		 
	
	clickable: boolean = true;
	resizableColumns: boolean = true;
	sortable : boolean = false;
	selectedRows: any[] = [];

	ngUnsubscribe: Subject<void> = new Subject();

	constructor( fb: FormBuilder ) {
		this.data.results = [];		
	}

	ngOnInit() : void {
		if(this.requestOptions && this.requestOptions.filter) {
			this.searchTerm = this.requestOptions.filter;
		}
		this.form = new FormGroup({
			searchTerm: new FormControl( this.searchTerm, Validators.required )
		});

		Observable.combineLatest(this.form.get('searchTerm').valueChanges.debounceTime(500))
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe( (text) => {
			this.searchTerm = text[0];
			if(this.searchTerm && this.searchTerm != "") {
				this.search();
			}
		});

		if(this.pagingBarLinks && this.data && this.data.page) {
			this.pagingBarLinks.initialPage = this.data.page;
		}
	}

	ngAfterViewInit(): void {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
    	this.ngUnsubscribe.complete();
	}

	columnValue(row, col) {
		if(col)
			return col.includes(".") ? this.scratchValue(col ,row ) : row[col];
		return '';			
	}

	evaluate(type,condition,value) {
		switch(type) {
			case 'icon' :
			case 'button' :
				switch(condition.operator) {
					case '==':
						return value == condition.value ? true : false;
					case '>':
						return value > condition.value ? true : false;
					case '<':
						return value < condition.value ? true : false;
					case '>=':
						return value >= condition.value ? true : false;
					case '<=':
						return value <= condition.value ? true : false;
					case '!=':
						return value != condition.value ? true : false;
				}
				break;
		}
	}

	evaluate2(type: string ,condition: any,value: any, row: any) {
		switch(type) {
			case 'button' :
				switch(condition.operator) {
					case '==':
						return value == row[condition.value] ? true : false;
					case '>':
						return value > row[condition.value] ? true : false;
					case '<':
						return value < row[condition.value] ? true : false;
					case '>=':
						return value >= row[condition.value] ? true : false;
					case '<=':
						return value <= row[condition.value] ? true : false;
					case '!=':
						return value != row[condition.value] ? true : false;
				}
				break;
		}
	}

	scratchValue(path,data) {
		let tree = path.split(".");
		let value;
		for( let i = 0; i < tree.length; i++ ) {
			if( value ) {
				value = value[tree[i]];
			} else {
				value = data[tree[i]];
			}
		};
		return value ? value : "";
	}

	onRowClick(event) {
		// console.log("onRowClick");
		this.onRowSelected.emit(event);
	}
	
	onButtonClick(name,row) {
		let event = {
			name : name,
			data : row
		};
		this.onButtonClicked.emit(event);
	}

	onStyleClick(col, row) {
		if( col.clickable ) {
			if( col.name ) {
				let event = {
					name : col.name,
					data : row
				};
				this.onButtonClicked.emit(event);
			}
			else {
				console.warn("VikkonTableError","La columna de tipo style debe contener el atributo name si el atributo clickable es true");
			}
		}
	}

	onButtonConditionClick(name,row,active) {
		if(active) {
			let event = {
				name : name,
				data : row
			};
			this.onButtonClicked.emit(event);
		}		
	}

	sort(sortEvent): void {
		this.data.results = [];
		this.sortBy = sortEvent.name;
		this.order = sortEvent.order.toLowerCase();  
		this.onSorted.emit({
			pageSize : this.pageSize,
			page : this.data.page,
			sortBy : this.sortBy,
			order : this.order
		});
	}
	 
	page(pagingEvent: IPageChangeEvent): void {}

	changePage(event) {
		this.data.results = [];
		this.pageSize = event.pageSize;	
		this.data.page = event.page;

		if(this.sortBy)
			event.sortBy = this.sortBy;
		if(this.order)
			event.order = this.order;

		this.onPageChanged.emit(event);
	}

	option(options,value) {
		let option = options.find(opt => opt.value == value)
		return option ? option.icon : '';
	}

	optionSize(options,value) {
		let option = options.find(opt => opt.value == value)
		return option ? `${option.iconSize}px` : '20px';
	}

	optionTooltip(options,value,row, col) {
		if(options) {
			if(col) {				
				let colTm: any = this.columns.find((c: any) => c.name === col.name);
				if(options !== colTm.options) {
					options = colTm.options;
				}
			}
			let option = options.find(opt => opt.value == value)
			if(option){
				if(option.tooltipName)
					return this.scratchValue(option.tooltipName,row);
				if(option.tooltip)
					return option.tooltip;
			}
		} else {
			let column = value;
			if(column){
				if(column.tooltipName)
					return this.scratchValue(column.tooltipName,row);
				if(column.tooltip)
					return column.tooltip;
			}
		}		
		return '';
	}

    onKeyPress(event) {
		// console.log("onKeyPress");
        if (event.key === 'Enter') {
			// this.search()
        }
	}

	changeSearch() {
		// console.log('change');
		this.searchTerm = this.form.get('searchTerm').value
		if( this.searchTerm == '' ) {
			this.clean();
		}
	}
	
	search() {
		// console.log("search");
		this.data.results = [];
		let text = this.form.get('searchTerm').value	
		this.onSearch.emit(text);
	}

	clean() {
		// console.log("clean");
		this.data.results = [];
		this.searchTerm = '';
		this.form.get('searchTerm').setValue('');			
		this.onClean.emit();
	}

	text(col, row) {
		if(col.textName)
			return this.scratchValue(col.textName,row);
		if(col.text)
			return col.text;
		return '';
	}

	showCol(col) {
		// console.log("col",col);
		// console.log("columns",this.columns);
	}

	refresh() {
		this.dtTable.refresh();
	}

	cleanRows() {
		this.data.results = [];
	}

	updateColumns(columns) {
		this.columns = columns;		
		this.dtTable.refresh();
	}

	iconDbTooltip(col, row) {
		const iconDB : { base: string, icon: string, tooltip: string, assets: string } = (col && col.iconDb) ? col.iconDb : undefined;
		return (iconDB && iconDB.base && iconDB.base !== '' && iconDB.tooltip && iconDB.tooltip !== '' && row[iconDB.base]) ? row[iconDB.base][iconDB.tooltip] : '';
	}
	iconDbSource(col, row) {
		const iconDB : { base: string, icon: string, tooltip: string, assets: string } = (col && col.iconDb) ? col.iconDb : undefined;
		return (iconDB && iconDB.base && iconDB.base !== '' && iconDB.icon && iconDB.icon !== '' && row[iconDB.base]) ? ( (iconDB.assets ? iconDB.assets : '') + row[iconDB.base][iconDB.icon]) : '';
	}
}

export class VikkonTableResponse {
	results: any[];
	pages: number; // draw
	page: number; // recordsFiltered
	count: number; // recordsTotal
}