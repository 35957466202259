import { Injectable } from '@angular/core';
import { FirebaseService } from './../../../shared/services/firebase.service';
import { FirebaseEventsService } from '../../../shared/services/firebaseEvents.service';
import { RequestService } from '../../../shared/services/request.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Observable, from, Subject, throwError } from 'rxjs';
import { map, catchError, tap, take, switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Http, Response } from '@angular/http';
import * as moment from 'moment';
import { MeasureVariable, MeasureVariables, RefrigeratorDevice } from './inventory.interface';
import { AngularFireDatabase } from '@angular/fire/database';
import { resolve } from 'url';
import { ajax } from 'rxjs/ajax';
import { components } from '../../../shared/constants/componentsConst';

@Injectable()
export class InventoryService {
    public onCustomerChange = new Subject<Number>();
    private lastConection: any = {};
    public info: any = {};
    public userInfo: any = {};
    public cursorTypeZero: any;
    public cursorTypeOne: any;
    public cursorTypeTwo: any;

    constructor(
        private afDB: AngularFirestore,
        private firebase: FirebaseService,
        private fbEvents: FirebaseEventsService,
        private request: RequestService,
        private auth: AuthService,
        private http: Http,
        private realtime: AngularFireDatabase
    ) { }

    setCustomerId(customerId: Number) {
        this.onCustomerChange.next(customerId);
    }

    public cursorReset() {
        delete this.cursorTypeZero;
        delete this.cursorTypeOne;
        delete this.cursorTypeTwo;
    }

    public saveServiceLife(enterpriseId, mac, timer) {
        return this.afDB.collection("enterprises").doc(enterpriseId)
            .collection('devices').doc(mac).set(timer, { merge: true }).then(() => { });
    }

    public updateLimits(enterpriseId, mac, limits) {
        return this.afDB.collection("enterprises").doc(enterpriseId).collection('devices').doc(mac).set({
            recipe: {
                '3c': {
                    value: limits.lowerLimit * 10,
                },
                '3d': {
                    value: limits.upperLimited * 10,
                }
            }
        }, { merge: true }).then(function () {
        });
    }

    public getRefrigeratorsByCustomer(customerId, limit, page, sortBy, order = 'asc', filter?): Promise<any> {
        return new Promise((resolve, reject) => {
            let link = `inventory/refrigerators-new?page=${page}&limit=${limit}&customerId=${customerId}&sortBy=${sortBy}:${order}`;
            if (filter) {
                link += `&filter=${filter}`;
            }
            this.request.get(link, null, 'inventory', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    public getDevicesByCustomer(customerId, limit, page, sortBy, order = 'asc', filter?): Promise<any> {
        return new Promise((resolve, reject) => {
            //let link = `inventory/devices?page=${page}&limit=${limit}&customerId=${customerId}&sortBy=${sortBy}`;
            let link = `inventory/devices-new?page=${page}&limit=${limit}&customerId=${customerId}&sortBy=${sortBy}:${order}`;
            if (filter) {
                link += `&filter=${filter}`;
            }
            this.request.get(link, null, 'inventory', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    public getRefrigeratorDetailsById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request.get(`inventory/refrigerator-details/${id}`, null, 'inventory', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getKpisByCustomer(customerId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request.get(`inventory/kpis?customerId=${customerId}`, null, 'refrigerators', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getRefrigeratorById(id) {
        return new Promise((resolve, reject) => {
            this.request.get(`refrigerators/${id}`, null, 'refrigerators', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getDeviceById(id) {
        return new Promise((resolve, reject) => {
            this.request.get(`devices/${id}`, null, 'devices', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getDeviceDetailsById(id) {
        return new Promise((resolve, reject) => {
            this.request.get(`inventory/devices-details/${id}`, null, 'devices', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getDeviceDetailsByIdPromise(id: number): Observable<any> {
        return from(this.getDeviceDetailsById(id)).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    public getRecipeByDeviceMac(mac) {
        return new Promise((resolve, reject) => {
            this.request.get(`recipe/${mac}`, null, 'devices', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getComponentsByMac(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.firebase.getDocOnChangebyMac(`enterprises/${userData.enterpriseId}/devices/`, mac).subscribe((device) => {
                        this.info = device;
                        if (device) {
                            if (device.components) {
                                for (const comp in device.components) {
                                    if (comp) {
                                        device.components[comp].componentId = comp;
                                    }
                                }
                                observer.next(Object.values(device.components));
                            } else {
                                observer.error('error');
                            }
                        }
                    });
                }
            });
        });
    }

    public getMeasureVariables(componentId: string, mac: string, count: number = 50): Observable<MeasureVariable> {
        const fields = 'fields=timestamp,timestampDate,timestampTime,value,measure';
        const urlReq = `measured-variable/component/${componentId}/${mac}?count=${count}&${fields}`;

        const promise = new Promise((resolve, reject) => {
            this.request.get(urlReq, null, 'production', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
        return from(promise).pipe(
            map((resp: MeasureVariables) => {
                const measureVariable: MeasureVariable = { ...resp, labels: [], series: [] };
                resp.results.forEach(dat => {
                    measureVariable.labels.push(`${dat.timestampDate}T${dat.timestampTime}`);
                    measureVariable.series.push({
                        meta: 'Fecha: ' + dat.timestampDate + '|Hora: ' + dat.timestampTime + '|' + (dat.measure ? dat.measure : ''),
                        value: +dat.value
                    });
                });
                return measureVariable as MeasureVariable;
            }),
            catchError(e => throwError(e))
        );
    }

    public syncElectricVariableLogs(componentId: string, mac: string, count: number = 50): Promise<any> {
        const urlReq = `measured-variable/${mac}/sync`;
        return new Promise((resolve, reject) => {
            this.request.post(urlReq, null, 'variables', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getDataDetail(deviceId: number, refrigeratorId: number): Observable<RefrigeratorDevice> {
        return from(new Promise((resolve, reject) => {
            this.request.get(`refrigerators/${refrigeratorId}`, null, 'production', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        })).pipe(
            map(({ brandName, serialNumber, manufacturerName, manufactured, activityStart, Device }) => ({ brandName, serialNumber, manufacturerName, manufactured, activityStart, modelo: Device.model }) as RefrigeratorDevice)
        );
    }

    public getFridgeDetail(refrigeratorId: number): Promise<any> {
        return this.request.get(`refrigerators/${refrigeratorId}`, null, 'production', 30000)
    }

    public getDeviceByMac(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    this.userInfo = data;
                    obs.unsubscribe();
                    return this.firebase.getDocOnChangebyMac(`enterprises/${userData.enterpriseId}/devices/`, mac).subscribe((device) => {
                        if (device) {
                            observer.next((device));
                        }
                        else {
                            observer.error("error");
                        }
                    })
                }

            });
        });
    }

    public getGpsDevices(customerId, mac): Observable<any> {
        return new Observable((observer) => {
            this.realtime.list(`devices-gps/${customerId}`, ref => ref.orderByChild('id').equalTo(mac))
                .valueChanges().subscribe((devices) => {
                    if (devices)
                        observer.next(devices);
                });
        })
    }

    public getRecipes(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.firebase.getDocOnChangebyMac(`enterprises/${userData.enterpriseId}/devices/`, mac).subscribe((device) => {
                        if (device) {
                            if (device.recipe) {
                                observer.next(Object.values(device.recipe));
                            } else {
                                observer.error("error");
                            }
                        }
                    })
                }

            });
        });
    }
    
    public getRecipesGraph(mac): Observable<any> {
        return this.auth.getUserData().pipe(
            take(1),
            switchMap( ({enterpriseId}) =>  this.firebase.getDocumentObs(`enterprises/${enterpriseId}/devices/`, mac))
        );
    }

    public getEventsType0(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.firebase.getEventsType0(`enterprises/${userData.enterpriseId}/events`, mac).subscribe((device) => {
                        observer.next((device));
                    });
                }
            });
        });
    }

    public getEventsType1(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.firebase.getEventsType1(`enterprises/${userData.enterpriseId}/events`, mac).subscribe((device) => {
                        observer.next((device));
                    });
                }
            });
        });
    }

    public getAlertsType1(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.fbEvents.getAlertsType1(`enterprises/${userData.enterpriseId}/events`, mac).subscribe((device) => {
                        observer.next((device));
                    });
                }
            });
        });
    }

    public getAlertsType2(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.fbEvents.getAlertsType2(`enterprises/${userData.enterpriseId}/events`, mac).subscribe((device) => {
                        observer.next((device));
                    });
                }
            });
        });
    }

    public getChartAlerts(pageSize: number = 10, mac: String, enterpriseId: String): Promise<any> {
        return new Promise((resolve, reject) => {
            let alerta;
            let path = `enterprises/${enterpriseId}/events`;

            var eventsRef = this.firebase.getFirestoreInstance().collection(path, ref => {
                return ref
                    .where('typeEvent', '==', 1)
                    .where('deviceId', '==', mac)
                    .orderBy('timestamp', 'desc')
                    .limit(pageSize)
            });

            let getData = eventsRef.snapshotChanges().subscribe((snapshot) => {
                if (snapshot.length > 0) {
                    getData.unsubscribe();
                    let items = [];
                    snapshot.forEach((item) => {
                        alerta = item.payload.doc.data();
                        if (this.firebase.getEventStructure()[alerta['eventCode']]) {
                            let aux = this.firebase.eventStructure[alerta['eventCode']];
                            alerta['message'] = String(aux.message).replace('{value}', alerta['value']);
                            alerta['label'] = String(aux.label).replace('{value}', alerta['value']);
                            alerta['family'] = String(aux.family).replace('{value}', alerta['value']);
                            alerta['familyCode'] = String(aux.familyCode).replace('{value}', alerta['value']);
                        }
                        if (alerta['message']) {
                            items.push(alerta);
                        }
                    });
                    resolve(items);
                } else {
                    resolve([]);
                }
            });
        });
    }

    public getEventsType0_2(pageSize: number = 10, mac: String, enterpriseId: String): Promise<any> {
        return new Promise((resolve, reject) => {
            let alerta;
            let path = `enterprises/${enterpriseId}/events`;

            var eventsRef = this.firebase.getFirestoreInstance().collection(path, ref => {
                if (this.cursorTypeZero) {
                    return ref
                        .where('typeEvent', '==', 0)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                        .startAfter(this.cursorTypeZero)
                } else {
                    return ref
                        .where('typeEvent', '==', 0)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                }
            });

            let getData = eventsRef.snapshotChanges().subscribe((snapshot) => {
                // console.log("getEventsType0_2",snapshot)
                if (snapshot.length > 0) {
                    getData.unsubscribe();
                    this.cursorTypeZero = snapshot[snapshot.length - 1].payload.doc;

                    let items = [];

                    snapshot.forEach((item) => {
                        alerta = item.payload.doc.data();
                        if (
                            this.firebase.getEventStructure()[alerta['eventCode']]
                        ) {
                            let aux = this.firebase.eventStructure[alerta['eventCode']];
                            alerta['message'] = String(aux.message).replace('{value}', alerta['value']);
                            alerta['label'] = String(aux.label).replace('{value}', alerta['value']);
                            alerta['family'] = String(aux.family).replace('{value}', alerta['value']);
                        }
                        // else {
                        // 	console.log("getEventStructure 0 -> else :", alerta)
                        // }

                        if (alerta['message']) {
                            items.push(alerta);
                        }
                        // else {
                        // 	console.log("message -> else 0 :", alerta)
                        // }
                    });

                    resolve(items);
                } else {
                    resolve([]);
                }
            });
        });
    }

    public getEventsType1_2(pageSize: number = 10, mac: String, enterpriseId: String): Promise<any> {
        return new Promise((resolve, reject) => {
            let alerta;
            let path = `enterprises/${enterpriseId}/events`;

            var eventsRef = this.firebase.getFirestoreInstance().collection(path, ref => {
                if (this.cursorTypeOne) {
                    return ref
                        .where('typeEvent', '==', 1)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                        .startAfter(this.cursorTypeOne)
                } else {
                    return ref
                        .where('typeEvent', '==', 1)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                }
            });

            let getData = eventsRef.snapshotChanges().subscribe((snapshot) => {
                if (snapshot.length > 0) {
                    getData.unsubscribe();
                    this.cursorTypeOne = snapshot[snapshot.length - 1].payload.doc;

                    let items = [];
                    snapshot.forEach((item) => {
                        alerta = item.payload.doc.data();
                        if (this.firebase.getEventStructure()[alerta['eventCode']]) {
                            let aux = this.firebase.eventStructure[alerta['eventCode']];
                            alerta['message'] = String(aux.message).replace('{value}', alerta['value']);
                            alerta['label'] = String(aux.label).replace('{value}', alerta['value']);
                            alerta['family'] = String(aux.family).replace('{value}', alerta['value']);
                        }
                        if (alerta['message']) {
                            items.push(alerta);
                        }
                    });
                    resolve(items);
                } else {
                    resolve([]);
                }
            });
        });
    }

    public getEventsType2_2(pageSize: number = 10, mac: String, enterpriseId: String): Promise<any> {
        return new Promise((resolve, reject) => {
            let alerta;
            let path = `enterprises/${enterpriseId}/events`;

            var eventsRef = this.firebase.getFirestoreInstance().collection(path, ref => {
                if (this.cursorTypeTwo) {
                    return ref
                        .where('typeEvent', '==', 2)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                        .startAfter(this.cursorTypeTwo)
                } else {
                    return ref
                        .where('typeEvent', '==', 2)
                        .where('deviceId', '==', mac)
                        .orderBy('timestamp', 'desc')
                        .limit(pageSize)
                }
            });

            let getData = eventsRef.snapshotChanges().subscribe((snapshot) => {
                if (snapshot.length > 0) {
                    getData.unsubscribe();
                    this.cursorTypeTwo = snapshot[snapshot.length - 1].payload.doc;

                    let items = [];
                    snapshot.forEach((item) => {
                        alerta = item.payload.doc.data();
                        if (this.firebase.getEventStructure()[alerta['eventCode']]) {
                            let aux = this.firebase.eventStructure[alerta['eventCode']];
                            alerta['message'] = String(aux.message).replace('{value}', alerta['value']);
                            alerta['label'] = String(aux.label).replace('{value}', alerta['value']);
                            alerta['family'] = String(aux.family).replace('{value}', alerta['value']);
                        }
                        if (alerta['message']) {
                            items.push(alerta);
                        }
                    });
                    resolve(items);
                } else {
                    resolve([]);
                }
            });
        });
    }

    public getEventsType2(mac): Observable<any> {
        return new Observable((observer) => {
            let userData: any;
            const obs = this.auth.getUserData().subscribe((data) => {
                if (data) {
                    userData = data;
                    obs.unsubscribe();
                    return this.firebase.getEventsType2(`enterprises/${userData.enterpriseId}/events`, mac).subscribe((device) => {
                        observer.next((device));
                    });
                }
            });
        });
    }

    public getLastStatus(id): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            this.request.get(`refrigerators-historial/last-status?refrigeratorId=${id}`, null, 'refrigerators', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
        return from(promise)
            .pipe(
                map((resp: any) => resp.status)
            );
    }

    public getNumAlerts(enterpriseId: string, mac: string): Observable<any> {
        return this.afDB.collection(`enterprises`).doc(enterpriseId).collection('devices').doc(mac)
            .snapshotChanges()
            .pipe(
                map((docData: any) => (docData.payload.data() && docData.payload.data().numAlerts) ? docData.payload.data().numAlerts : '0')
            );
    }

    public getlastConnection(enterpriseId: string, mac: string): Observable<any> {
        return this.afDB.collection(`enterprises`).doc(enterpriseId).collection('devices').doc(mac)
            .snapshotChanges()
            .pipe(
                map((docData: any) => (docData.payload.data() && docData.payload.data().lastSync) ? docData.payload.data().lastSync : '-')
            );
    }

    public getActiveCustomers() {
        return new Promise((resolve, reject) => {
            this.request.get(`customers/active-list`, null, 'customers', 30000).then((customers) => {                
                resolve(customers);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    get lastConections(): any {
        return this.lastConection;
    }

    public getComponent(username: String, key: String): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`https://io.adafruit.com/api/v2/${username}/feeds/${key}`)
                .timeout(30000)
                .map((res: Response) => { return res })
                .subscribe((data) => {
                    resolve(data.json());
                }, (error: Response) => {
                    reject(error.json());
                });
        });
    }

    public getDataChart(username, key): Promise<any> {
        return new Promise((resolve, reject) => {
            let start_time = moment().subtract(5, 'hours').format("YYYY-MM-DDTHH:mm") + 'Z';
            let end_time = moment().format("YYYY-MM-DDTHH:mm") + 'Z';
            let link = `https://io.adafruit.com/api/v2/${username}/feeds/${key}/data/chart?start_time=${start_time}&end_time=${end_time}`
            this.http.get(link)
                .timeout(100000)
                .map((res: Response) => { return res })
                .subscribe((data) => {
                    resolve(data.json());
                }, (error: Response) => {
                    reject(error.json());
                });
        });
    }

    public getTypeFiles() {
        return new Promise((resolve, reject) => {
            this.request.get(`type-files?page=1&limit=5&sortBy=id:asc,name:asc&fields=id,name`, null, 'configs', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getDocuments(model) {
        return new Promise((resolve, reject) => {
            this.request.get(`files-manager?page=1&limit=10&sortBy=id:asc,model:asc&filter=${model}`, null, 'configs', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getLocationsbyDeviceId(deviceId) {
        return new Promise((resolve, reject) => {
            this.request.get(`devices/locations/${deviceId}`, null, 'devices', 30000).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getTraffic(enterpriseId, mac): Observable<any> {
        return new Observable((observer) => {
            return this.afDB
                .collection("enterprises")
                .doc(enterpriseId)
                .collection('devices')
                .doc(mac)
                .valueChanges().subscribe((data: any) => {
                    if (data.accumulated) {
                        if (data.accumulated.traffic) {
                            observer.next(data.accumulated.traffic.value);
                        }
                    }
                });
        });
    }

    getBrands(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`brands/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getRefrigeratorModels(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`refrigerator-models/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getDeviceModels(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`device-models/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getBrandsDevices(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`brands-devices/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }


    getManufacturers(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`manufacturers/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getLocations(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`refrigerator-locations/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getStatusRefrigerator(): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.get(`refrigerator-status/active-list`, null, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateRefrigerator(id: number, data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.put(`inventory/update-refrigerator/${ id }`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateDevice(id: number, data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.put(`inventory/update-device/${ id }`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateLocation(data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.put(`devices/activate/change-location`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateStatusRef(data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.post(`refrigerators-historial`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateOutlet(id: number, data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.put(`inventory/update-outlet/${ id }`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    updateOutletDetail(outletId: number, data: any): Observable<any> {
        const promise =  new Promise((resolve, reject) => {
            this.request.put(`inventory/update-details-outlet/${ outletId }`, data, 'production', 30000)
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

    getSelfTest(mac: string): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            this.request.get(`self-tests/${ mac }?page=1&limit=1&sortBy=id:desc`, null, 'production', 30000)
                .then( data => resolve(data))
                .catch( error => reject(error))
        });
        return from(promise).pipe(
            take(1),
            map( (data: any) => (data.results && data.results.length > 0) ? data.results[0] : []),
            map( (test: any) => (test.tests && test.tests.length > 0) ? {tests: test.tests, id: test.id, date: test.unixTimestamp ? moment.unix(test.unixTimestamp).format('DD/MM/YYYY HH:mm:ss') : '' } : []),
            map( (dataTests: any) => {
                const tests = dataTests.tests;

                const objDefault = { name: '', label: '', labelRep: '', type: '', measure: '', value: undefined };

                let response = undefined, type='', entryComponent='', row=0, cont=0;
                let allGreen = true, allRed = true;
                let entryOne = false;
                if(tests) {
                    tests.forEach( (res: any) => {
                        if(!response) {
                            response = {};
                        }
                        if( res.test.includes('current') ) {
                            type = 'Voltaje';
                            entryComponent = res.test.replace('-current','');
                        } else {
                            type = 'Corriente';
                            entryComponent = res.test;
                        }
                        const component : any = components.find( component => component.name == entryComponent);
                        if( component ) {
                            entryOne = true;
                            if( cont===0 || cont > 2 ) {
                                cont = 0;
                                row++;
                                response[row] = [];
                            }
                            cont++;
                            
                            response[row].push({ 
                                name: entryComponent,
                                label: component.label ? component.label : entryComponent,
                                labelRep: `${ component.label ? component.label : entryComponent } - ${ type }`,
                                type,
                                measure: component.measure ? component.measure : '',
                                value: res.result ? res.result : false
                            });
                            
                            if(res.result === true) {
                                allRed = false;
                            } else {
                                allGreen = false;
                            }
                        }
                    });
                }
                if(entryOne===false) {
                    allRed = false;
                    allGreen = false;
                }
                if(!response) {
                    response = {};
                }

                for (let index = 0; index < 3; index++) {
                    if(!response[index + 1]) {
                        response[index + 1] = [objDefault, objDefault, objDefault];
                    } else {
                        for (let idet = 0; idet < 3; idet++) {
                            if(response[index + 1].length < (idet + 1)) {
                                response[index + 1].push(objDefault);
                            }
                        }
                    }
                }

                

                let testText='', testClass = '';
                if(allGreen===true) {
                    testText='SATISFACTORIO | Sin falla'; testClass = 'container-diagnostic-green';
                } else if(allRed===true) {
                    testText='INSATISFACTORIO | Atención inmediata'; testClass = 'container-diagnostic-red';
                } else {
                    if(entryOne === false) {
                        testText='REVISAR | Sin Elemento'; testClass = 'container-diagnostic-white';
                    } else {
                        testText='REVISAR | Elemento con falla'; testClass = 'container-diagnostic-yellow';    
                    }
                }

                return { tests: response, id: dataTests.id, date: dataTests.date, testText, testClass };
            }),
            catchError( e => throwError(e))
        );
    }

    getCodesCountries(): Observable<any> {
        // const url = 'https://restcountries.eu/rest/v2/all?fields=alpha2Code;callingCodes;flag';
        const url = 'https://restcountries.com/v2/all?fields=alpha2Code,callingCodes,flags';
        return ajax( url ).pipe(
            take(1),
            map( ajaxResp => ajaxResp.response as Array<any>),
            map( datas => datas.filter( val => (val.callingCodes && val.callingCodes.length >= 1 && val.callingCodes[0]!=='')) ),
            map( respData => {
                return respData.map( resp => {
                    const callingCode = (resp.callingCodes && resp.callingCodes.length >= 1) ? resp.callingCodes[0] : 0;
                    const flagPng = (resp.flags && resp.flags.png)  ? resp.flags.png : resp.flags.svg;
                    return { flag: flagPng, label: `${resp.alpha2Code} - ${callingCode}`, callingCode: +callingCode }
                })
            }),
            catchError( e => throwError(e))
        );
    }

    getAllAlerts(mac: string, enterpriseId: string): Observable<any> {
        let alerta;
        let path = `enterprises/${enterpriseId}/events`;
        var eventsRef = this.firebase.getFirestoreInstance().collection(path, ref => {
            return ref
                .where('deviceId', '==', mac)
                .orderBy('timestamp', 'desc')
        });
        return eventsRef.get().pipe(
            map( docData => {
                let items = [], label = '', family='', message='';
                docData.forEach( doc => { 
                    alerta = doc.data();
                    label = ''; family=''; message='';
                    if (this.firebase.getEventStructure()[alerta['eventCode']]) {
                        let aux = this.firebase.eventStructure[alerta['eventCode']];
                        label = aux.label;
                        family = aux.family;
                        message = String(aux.message).replace('{value}', alerta.value);
                    }
                    items.push(`${alerta.typeEvent},${alerta.eventCode},${alerta.value},${label},${family},${message},${alerta.date},${alerta.origin}`);
                });
                return items;
            })
        );
    }

    getPhotos(mac:string, serialNumber: string):Promise<any>{
        const url = `photos?id=${mac}`
        return this.request.get(url, null, 'production', 30000)        
    }
}
