import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from './../services/auth.service';
import { PrivilegesService } from '../services/privileges.service';

import { IPrivilegeUser, IModules, IRoles, IUserData } from '../interfaces/privileges.interface';
import { MessageService } from '../services/message.service';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
    isActive = true;
    showMenu = '';
    showSubMenu = '';

    public modulesView = [];

    private modules$: Subscription;
    private privilegeUser$: Subscription;
    private messages$: Subscription;

    private modulesSystem: IModules;
    private privilegesUser: IPrivilegeUser;
    private roles: IRoles = {};

    public reloadPage = false;

    private userData;

    private oneSignal;

    public today = Date.now();

    constructor(
        private auth: AuthService,
        private message: MessageService,
        private router: Router,
        public privilegesService: PrivilegesService
    ) {
        this.auth.getUserData().pipe(take(1)).subscribe((data) => {
            const loading = this.privilegesService.getItem('userDataSliderLoad', false);
            if (loading === undefined || loading === 'true') {
                let loadST = this.privilegesService.getItem('userDataSliderLoad', false);
                if ( loading === undefined) {
                    this.privilegesService.setItem('userDataSliderLoad', 'true');
                    loadST = this.privilegesService.getItem('userDataSliderLoad', false);
                }
                this.userData = data;
                const dataUser: IUserData = {
                    enterpriseId: data.enterpriseId,
                    id: data.id,
                    timeZone: data.timeZone ? data.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
                };
                this.privilegesService.timezone = data.timeZone ? data.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
                this.privilegesService.setItem('userDataSlider', JSON.stringify(dataUser));
                this.modules$ = this.privilegesService.getModulesInit(data.enterpriseId).subscribe(privileges => {
                    loadST = this.privilegesService.getItem('userDataSliderLoad', false);
                    if (loadST === 'true') {
                        this.modulesSystem = privileges.modules;
                        this.roles = privileges.roles;
                        this.modulesView = [];
                        if (this.privilegesUser) {
                            // console.log('🔥', 'getModules', this.reloadPage);
                            this.privilegesService.loadMenu(this.privilegesUser, this.modulesSystem, this.roles, this.modulesView);
                            if (this.reloadPage === true) { this.router.navigate(['/']); }
                            this.reloadPage = true;
                        }
                    } else {
                        this.privilegesService.setItem('userDataSliderLoad', 'true');
                    }
                });
                this.privilegeUser$ = this.privilegesService.getUserPrivileges(data.enterpriseId, data.id).subscribe(privileges => {
                    loadST = this.privilegesService.getItem('userDataSliderLoad', false);
                    if (loadST === 'true') {
                        this.privilegesUser = privileges;
                        this.modulesView = [];
                        if (this.modulesSystem) {
                            // console.log('🔥', 'getPrivileges', this.reloadPage);
                            this.privilegesService.loadMenu(this.privilegesUser, this.modulesSystem, this.roles, this.modulesView);
                            if (this.reloadPage === true) { this.router.navigate(['/']); }
                            this.reloadPage = true;
                        }
                    } else {
                        this.privilegesService.setItem('userDataSliderLoad', 'true');
                    }
                });
                this.tagsData();
            } else {
                this.privilegesService.setItem('userDataSliderLoad', 'true');
            }
        });
    }

    ngOnInit(): void {
        this.messages$ = this.privilegesService.messageUser$.subscribe(text => {
            if (text !== '') {
                this.message.errorToast('¡Oh, no!', text, undefined, undefined, null);
                this.privilegesService.message = '';
            }
        });
        const pahtUrl = window.location.origin;

        if ( pahtUrl.indexOf('localhost') < 0 ) {
            this.oneSignal = window['OneSignal'] || [];
            if (this.oneSignal) {
                this.oneSignal.push(['init', {
                    appId: pahtUrl.indexOf('localhost') >= 0 ? environment.appIdOnSignalLocal : environment.appIdOnSignal,
                    autoRegister: false,
                    allowLocalhostAsSecureOrigin: true,
                    notifyButton: { enable: false },
                    promptOptions: {
                        slidedown: { enabled: true, autoPrompt: true, timeDelay: 40, pageViews: 4 }
                    }
                }]);
                this.oneSignal.push(() => {
                    this.oneSignal.push(['registerForPushNotifications']);
                });
                this.oneSignal.push(async () => {
                    await this.oneSignal.isPushNotificationsEnabled((isEnabled) => {
                        if (isEnabled) {
                            console.log('Push notifications are enabled!', '✅');
                        } else {
                            console.log('Push notifications are not enabled yet.', '❌');
                        }
                    });
                    const isPushSupported = await this.oneSignal.isPushNotificationsSupported();
                    if (isPushSupported) {
                        console.log('Push notifications are supported!!!', '✅');
                    } else {
                        console.log('Push notifications are not supported', '❌');
                    }

                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.modules$) {
            this.modules$.unsubscribe();
        }
        if (this.privilegeUser$) {
            this.privilegeUser$.unsubscribe();
        }
        if (this.messages$) {
            this.messages$.unsubscribe();
        }
        this.privilegesService.removeStorage = 'prevPageSlider';
        this.privilegesService.removeStorage = 'userDataSlider';
        this.privilegesService.removeStorage = 'userDataSliderLoad';
    }
    async tagsData() {
        if ( this.userData && this.oneSignal ) {
            this.oneSignal.push(async () => {
                // const tags = {};
                if ( this.userData.uid ) {
                    // tags['id'] = this.userData.uid;
                    this.oneSignal.sendTag('id', this.userData.uid);
                }
                if ( this.userData.enterpriseId ) {
                    // tags['enterpriseId'] = this.userData.enterpriseId;
                    this.oneSignal.sendTag('enterpriseId', this.userData.enterpriseId);
                }
                if ( this.userData.email ) {
                    // tags['email'] = this.userData.email;
                    this.oneSignal.sendTag('email', this.userData.email);
                }
                if ( this.userData.phone ) {
                    // tags['phone'] = this.userData.phone;
                    this.oneSignal.sendTag('phone', this.userData.phone);
                }
                if ( this.userData.gender ) {
                    // tags['gender'] = this.userData.gender;
                    this.oneSignal.sendTag('gender', this.userData.gender);
                }
                if ( this.userData.firstName ) {
                    // tags['firstName'] = this.userData.firstName;
                    this.oneSignal.sendTag('firstName', this.userData.firstName);
                }
                if ( this.userData.lastName ) {
                    // tags['lastName'] = this.userData.lastName;
                    this.oneSignal.sendTag('lastName', this.userData.lastName);
                }
                if ( this.userData.birthDate ) {
                    // tags['birthDate'] = this.userData.birthDate;
                    this.oneSignal.sendTag('birthDate', this.userData.birthDate);
                }
                if ( this.userData.profileId ) {
                    // tags['profileId'] = this.userData.profileId;
                    this.oneSignal.sendTag('profileId', this.userData.profileId);
                }
                if ( this.userData.isActive ) {
                    // tags['isActive'] = this.userData.isActive;
                    this.oneSignal.sendTag('isActive', this.userData.isActive);
                }
                if ( this.userData.customers ) {
                    // tags['customers'] = this.userData.customers;
                    this.oneSignal.sendTag('customers', this.userData.customers);
                    const list: [] = this.userData.customers.split(',');
                    if ( list && list.length > 0 ) {
                        list.forEach( customer => {
                            // tags[`customer-${ customer }`] = true;
                            this.oneSignal.sendTag(`customer-${ customer }`, true);
                        });
                    }
                }
                // await this.oneSignal.sendTags(tags);
            });
        }
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }
    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }
    eventCalled() {
        this.isActive = !this.isActive;
    }
    ngAfterViewInit() {
        $(function () {
            $('.sidebartoggler').on('click', function () {
                if ($('body').hasClass('mini-sidebar')) {
                    $('body').trigger('resize');
                    $('.scroll-sidebar, .slimScrollDiv')
                        .css('overflow', 'hidden')
                        .parent()
                        .css('overflow', 'visible');
                    $('body').removeClass('mini-sidebar');
                    $('.navbar-brand span').show();
                    // $(".sidebartoggler i").addClass("ti-menu");
                } else {
                    $('body').trigger('resize');
                    $('.scroll-sidebar, .slimScrollDiv')
                        .css('overflow-x', 'visible')
                        .parent()
                        .css('overflow', 'visible');
                    $('body').addClass('mini-sidebar');
                    $('.navbar-brand span').hide();
                    // $(".sidebartoggler i").removeClass("ti-menu");
                }
            });
        });
    }
}
