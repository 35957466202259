import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';
import { catchError, map, take, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable()
export class FirebaseService {
    public alerta ;
	public eventStructure = {
		"A01": {
			"eventCode": "A01",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "No se detecta módulo antirrobo",
			"message": ""
		},
		"A56": {
			"eventCode": "A56",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "No se detecta módulo antirrobo",
			"message": "No se detecta módulo antirrobo"
		},
		"AAB": {
			"eventCode": "AAB",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "No se detecta módulo antirrobo",
			"message": "Módulo antirrobo estuvo sin conexión durante {value} minutos"
		},
		"A02": {
			"eventCode": "A02",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Error de comunicación de módulo antirrobo",
			"message": "Error de comunicación de módulo antirrobo"
		},
		"A57": {
			"eventCode": "A57",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Error de comunicación de módulo antirrobo",
			"message": "No se reestableció comunicación con el módulo antirrobo"
		},
		"AAC": {
			"eventCode": "AAC",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Error de comunicación de módulo antirrobo",
			"message": ""
		},
		"A03": {
			"eventCode": "A03",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Golpe",
			"message": "Golpe al equipo detectado"
		},
		"A58": {
			"eventCode": "A58",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Golpe",
			"message": "Golpe severo al equipo detectado"
		},
		"AAD": {
			"eventCode": "AAD",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Golpe",
			"message": ""
		},
		"A04": {
			"eventCode": "A04",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Vibración",
			"message": "Vibraciones en el equipo"
		},
		"A59": {
			"eventCode": "A59",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Vibración",
			"message": "El equipo sufrido vibración excesiva de manera prolongada"
		},
		"AAE": {
			"eventCode": "AAE",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Vibración",
			"message": "Se detectaron vibraciones en el equipo de manera prolongada por {value} minutos"
		},
		"A05": {
			"eventCode": "A05",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Caída",
			"message": "Caída del equipo detectada"
		},
		"A5A": {
			"eventCode": "A5A",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Caída",
			"message": ""
		},
		"AAF": {
			"eventCode": "AAF",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Caída",
			"message": ""
		},
		"A06": {
			"eventCode": "A06",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Equipo reubicado",
			"message": "Equipo reubicado"
		},
		"A5B": {
			"eventCode": "A5B",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Equipo reubicado",
			"message": ""
		},
		"AB0": {
			"eventCode": "AB0",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Equipo reubicado",
			"message": ""
		},
		"A07": {
			"eventCode": "A07",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 0,
			"label": "Desactivación por reubicación no autorizada",
			"message": ""
		},
		"A5C": {
			"eventCode": "A5C",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 1,
			"label": "Desactivación por reubicación no autorizada",
			"message": "Desactivación por reubicación no autorizada"
		},
		"AB1": {
			"eventCode": "AB1",
			"familyCode": "A",
			"family": "Antirrobo",
			"typeEvent": 2,
			"label": "Desactivación por reubicación no autorizada",
			"message": ""
		},
		"B01": {
			"eventCode": "B01",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 0,
			"label": "Batería conectada",
			"message": "Se conectó la batería."
		},
		"B56": {
			"eventCode": "B56",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 1,
			"label": "Batería conectada",
			"message": ""
		},
		"BAB": {
			"eventCode": "BAB",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 2,
			"label": "Batería conectada",
			"message": ""
		},
		"B02": {
			"eventCode": "B02",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 0,
			"label": "Batería desconectada",
			"message": "Desconexión / sin alimentación de la batería."
		},
		"B57": {
			"eventCode": "B57",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 1,
			"label": "Batería desconectada",
			"message": "Batería desconectada y/o no proporciona voltaje."
		},
		"BAC": {
			"eventCode": "BAC",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 2,
			"label": "Batería desconectada",
			"message": "Sin alimentación de la batería poro al menos {value} minutos"
		},
		"B03": {
			"eventCode": "B03",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 0,
			"label": "Batería baja",
			"message": "Batería baja ({value}%)."
		},
		"B58": {
			"eventCode": "B58",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 1,
			"label": "Batería baja",
			"message": "Batería interna en nivel crítico: {value}%"
		},
		"BAD": {
			"eventCode": "BAD",
			"familyCode": "B",
			"family": "Batería",
			"typeEvent": 2,
			"label": "Batería baja",
			"message": "Batería intermna en nivel crítico: tiempo restante {value} minutos"
		},
		"C01": {
			"eventCode": "C01",
			"familyCode": "C",
			"family": "Corriente",
			"typeEvent": 0,
			"label": "Sobre corriente",
			"message": "Sobrecorriente de {value} A."
		},
		"C56": {
			"eventCode": "C56",
			"familyCode": "C",
			"family": "Corriente",
			"typeEvent": 1,
			"label": "Sobre corriente",
			"message": "Sobrecorriente de {value} A."
		},
		"CAB": {
			"eventCode": "CAB",
			"familyCode": "C",
			"family": "Corriente",
			"typeEvent": 2,
			"label": "Sobre corriente",
			"message": "Sobrecorriente por un periodo de {value} segundos."
		},
		"F01": {
			"eventCode": "F01",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Ventilador bloqueado : Evaporador",
			"message": ""
		},
		"F56": {
			"eventCode": "F56",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Ventilador bloqueado : Evaporador",
			"message": "Ventilador del evaporador bloqueado"
		},
		"FAB": {
			"eventCode": "FAB",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Ventilador bloqueado : Evaporador",
			"message": "Ventilador del evaporador estuvo bloqueado por un tiempo de {value} minutos"
		},
		"F02": {
			"eventCode": "F02",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Ventilador bloqueado: Condensador",
			"message": ""
		},
		"F57": {
			"eventCode": "F57",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Ventilador bloqueado: Condensador",
			"message": "Ventilador del condensador bloqueado"
		},
		"FAC": {
			"eventCode": "FAC",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Ventilador bloqueado: Condensador",
			"message": "Ventilador del condensador estuvo bloqueado por un tiempo de {value} minutos"
		},
		"F03": {
			"eventCode": "F03",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Tiempo de encendido automático del ventilador evaporador",
			"message": ""
		},
		"F58": {
			"eventCode": "F58",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Tiempo de encendido automático del ventilador evaporador",
			"message": "No se detuvo el ventilador del evaporador"
		},
		"FAD": {
			"eventCode": "FAD",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Tiempo de encendido automático del ventilador evaporador",
			"message": "No se ha detenido el ventilador del evaporador después de {value} minutos"
		},
		"F04": {
			"eventCode": "F04",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Arranque del ventilador del evaporador",
			"message": ""
		},
		"F59": {
			"eventCode": "F59",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Arranque del ventilador del evaporador",
			"message": "No encendio el ventilador del evaporador"
		},
		"FAE": {
			"eventCode": "FAE",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Arranque del ventilador del evaporador",
			"message": ""
		},
		"F05": {
			"eventCode": "F05",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Arranque del ventilador del compresor",
			"message": ""
		},
		"F5A": {
			"eventCode": "F5A",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Arranque del ventilador del compresor",
			"message": "No encendio el ventilador del compresor"
		},
		"FAF": {
			"eventCode": "FAF",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Arranque del ventilador del compresor",
			"message": ""
		},
		"F06": {
			"eventCode": "F06",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Retardo de encendido del ventilador del evaporador después de un deshielo",
			"message": ""
		},
		"F5B": {
			"eventCode": "F5B",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Retardo de encendido del ventilador del evaporador después de un deshielo",
			"message": "No se ha cumplido el retardo para que el ventilador del evaporador encienda después de un deshielo"
		},
		"FB0": {
			"eventCode": "FB0",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Retardo de encendido del ventilador del evaporador después de un deshielo",
			"message": ""
		},
		"F07": {
			"eventCode": "F07",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Apagado del ventilador del evaporador",
			"message": "Ventilador del evaporador apagado por {value} minutos"
		},
		"F5C": {
			"eventCode": "F5C",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Apagado del ventilador del evaporador",
			"message": ""
		},
		"FB1": {
			"eventCode": "FB1",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Apagado del ventilador del evaporador",
			"message": ""
		},
		"F08": {
			"eventCode": "F08",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Encendido del ventilador del evaporador",
			"message": "Encendido del ventilador del evaporador por {value} minutos"
		},
		"F5D": {
			"eventCode": "F5D",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Encendido del ventilador del evaporador",
			"message": ""
		},
		"FB2": {
			"eventCode": "FB2",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Encendido del ventilador del evaporador",
			"message": ""
		},
		"F09": {
			"eventCode": "F09",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Apagado del ventilador del compresor",
			"message": "Ventilador del evaporador apagado por {value} minutos"
		},
		"F5E": {
			"eventCode": "F5E",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Apagado del ventilador del compresor",
			"message": ""
		},
		"FB3": {
			"eventCode": "FB3",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Apagado del ventilador del compresor",
			"message": ""
		},
		"F0A": {
			"eventCode": "F0A",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 0,
			"label": "Encendido del ventilador del compresor",
			"message": "Encendido del ventilador del evaporador por {value} minutos"
		},
		"F5F": {
			"eventCode": "F5F",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 1,
			"label": "Encendido del ventilador del compresor",
			"message": ""
		},
		"FB4": {
			"eventCode": "FB4",
			"familyCode": "F",
			"family": "Ventilador",
			"typeEvent": 2,
			"label": "Encendido del ventilador del compresor",
			"message": ""
		},
		"H01": {
			"eventCode": "H01",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 0,
			"label": "Deshielo",
			"message": "Deshielo activado por {value} segundos "
		},
		"H56": {
			"eventCode": "H56",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 1,
			"label": "Deshielo",
			"message": "El equipo no ha entrado a ciclo de deshielo."
		},
		"HAB": {
			"eventCode": "HAB",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 2,
			"label": "Deshielo",
			"message": "El equipo entró a ciclo de deshielo después de {value} minutos"
		},
		"H02": {
			"eventCode": "H02",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 0,
			"label": "Exceso de duración del deshielo",
			"message": ""
		},
		"H57": {
			"eventCode": "H57",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 1,
			"label": "Exceso de duración del deshielo",
			"message": "Duración de deshielo excedida"
		},
		"HAC": {
			"eventCode": "HAC",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 2,
			"label": "Exceso de duración del deshielo",
			"message": "Duración de deshielo excedida por un periodo de {value} minutos "
		},
		"H03": {
			"eventCode": "H03",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 0,
			"label": "Drenaje",
			"message": "Modo Drenaje activo durante {value} minutos"
		},
		"H58": {
			"eventCode": "H58",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 1,
			"label": "Drenaje",
			"message": "Exceso de tiempo en modo drenaje"
		},
		"HAD": {
			"eventCode": "HAD",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 2,
			"label": "Drenaje",
			"message": "Exceso de tiempo en modo drenaje por un periodo de {value} minutos"
		},
		"H04": {
			"eventCode": "H04",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 0,
			"label": "Temperatura alta al terminar deshielo",
			"message": ""
		},
		"H59": {
			"eventCode": "H59",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 1,
			"label": "Temperatura alta al terminar deshielo",
			"message": "Temperatura alta al finalizar deshielo {value}°"
		},
		"HAE": {
			"eventCode": "HAE",
			"familyCode": "H",
			"family": "Deshielo",
			"typeEvent": 2,
			"label": "Temperatura alta al terminar deshielo",
			"message": "Temperatura alta al finalizar el deshielo por un periodo de {value} minutos"
		},
		"M01": {
			"eventCode": "M01",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 0,
			"label": "No se detecta memoria interna",
			"message": ""
		},
		"M56": {
			"eventCode": "M56",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 1,
			"label": "No se detecta memoria interna",
			"message": "Memoria interna no detectada"
		},
		"MAB": {
			"eventCode": "MAB",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 2,
			"label": "No se detecta memoria interna",
			"message": "No se detectó memoria interna por un lapso de {value} minutos"
		},
		"M02": {
			"eventCode": "M02",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 0,
			"label": "Error de comunicación con memoria interna",
			"message": "Error de comunicación con memoria interna"
		},
		"M57": {
			"eventCode": "M57",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 1,
			"label": "Error de comunicación con memoria interna",
			"message": "No se reestableció comunicación con la memoria interna"
		},
		"MAC": {
			"eventCode": "MAC",
			"familyCode": "M",
			"family": "Memoria",
			"typeEvent": 2,
			"label": "Error de comunicación con memoria interna",
			"message": ""
		},
		"P01": {
			"eventCode": "P01",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 0,
			"label": "Puerta abierta",
			"message": "Puerta abierta"
			// "message": "Puerta abierta por {value} segundos"
		},
		"P56": {
			"eventCode": "P56",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 1,
			"label": "Puerta abierta",
			"message": "Se ha excedido el tiempo de puerta abierta."
		},
		"PAB": {
			"eventCode": "PAB",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 2,
			"label": "Puerta abierta",
			"message": "Se ha excedido el tiempo de puerta abierta por un periodo de {value} segundos"
		},
		"P02": {
			"eventCode": "P02",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 0,
			"label": "Cierre de puerta",
			"message": "Puerta cerrada"
			// "message": "Puerta cerrada por {value} minutos"
		},
		"P57": {
			"eventCode": "P57",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 1,
			"label": "Cierre de puerta",
			"message": ""
		},
		"PAC": {
			"eventCode": "PAC",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 2,
			"label": "Cierre de puerta",
			"message": ""
		},
		"P03": {
			"eventCode": "P03",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 0,
			"label": "Aperturas diarias máximas",
			"message": ""
		},
		"P58": {
			"eventCode": "P58",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 1,
			"label": "Aperturas diarias máximas",
			"message": "Se ha excedido el número de aperturas diarias permitidas ({value} aperturas)"
		},
		"PAD": {
			"eventCode": "PAD",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 2,
			"label": "Aperturas diarias máximas",
			"message": ""
		},
		"P04": {
			"eventCode": "P04",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 0,
			"label": "Aperturas diarias mínimas",
			"message": ""
		},
		"P59": {
			"eventCode": "P59",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 1,
			"label": "Aperturas diarias mínimas",
			"message": "No se ha cumplido con el mínimo de aperturas diarias establecidas ({value} aperturas)"
		},
		"PAE": {
			"eventCode": "PAE",
			"familyCode": "P",
			"family": "Puerta",
			"typeEvent": 2,
			"label": "Aperturas diarias mínimas",
			"message": ""
		},
		"Q01": {
			"eventCode": "Q01",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 0,
			"label": "No se detecta módulo de calidad de energía",
			"message": ""
		},
		"Q56": {
			"eventCode": "Q56",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 1,
			"label": "No se detecta módulo de calidad de energía",
			"message": "No se detecta módulo de calidad de energía"
		},
		"QAB": {
			"eventCode": "QAB",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 2,
			"label": "No se detecta módulo de calidad de energía",
			"message": "Módulo antirrobo estuvo sin conexión durante {value} minutos"
		},
		"Q02": {
			"eventCode": "Q02",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 0,
			"label": "Error de comunicación de módulo de calidad de energía",
			"message": "Error de comunicación de módulo de calidad de energía"
		},
		"Q57": {
			"eventCode": "Q57",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 1,
			"label": "Error de comunicación de módulo de calidad de energía",
			"message": "No se reestableció comunicación con el módulo de calidad de energía después de un lapso de {value} minutos"
		},
		"QAC": {
			"eventCode": "QAC",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 2,
			"label": "Error de comunicación de módulo de calidad de energía",
			"message": ""
		},
		"Q03": {
			"eventCode": "Q03",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 0,
			"label": "Sobre consumo",
			"message": ""
		},
		"Q58": {
			"eventCode": "Q58",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 1,
			"label": "Sobre consumo",
			"message": "Consumo excesivo de energía de {value} kWh"
		},
		"QAD": {
			"eventCode": "QAD",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 2,
			"label": "Sobre consumo",
			"message": "Consumo de energía excesivo durante un lapso de {value} horas"
		},
		"Q04": {
			"eventCode": "Q04",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 0,
			"label": "No está conectado a la línea",
			"message": ""
		},
		"Q59": {
			"eventCode": "Q59",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 1,
			"label": "No está conectado a la línea",
			"message": "Módulo de calidad de energía sin conexión a la línea"
		},
		"QAE": {
			"eventCode": "QAE",
			"familyCode": "Q",
			"family": "Calidad de energía",
			"typeEvent": 2,
			"label": "No está conectado a la línea",
			"message": "Módulo de calidad de energía estuvo sin conexión a la línea durante {value} minutos"
		},
		"R01": {
			"eventCode": "R01",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Señal de compresor",
			"message": "Se manda señal de arranque al compresor."
		},
		"R56": {
			"eventCode": "R56",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Señal de compresor",
			"message": ""
		},
		"RAB": {
			"eventCode": "RAB",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Señal de compresor",
			"message": ""
		},
		"R02": {
			"eventCode": "R02",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Arranque en falso (termiquea)",
			"message": "Arranque en falso del compresor (termiquea)."
		},
		"R57": {
			"eventCode": "R57",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Arranque en falso (termiquea)",
			"message": "Arranque en falso del compresor"
		},
		"RAC": {
			"eventCode": "RAC",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Arranque en falso (termiquea)",
			"message": "Arranque en falso del compresor.(intento de arranque duró {value} segundos)."
		},
		"R03": {
			"eventCode": "R03",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Compresor no arranca: relevador está dañado",
			"message": "Compresor no ha arrancado: Posible relevador dañado"
		},
		"R58": {
			"eventCode": "R58",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Compresor no arranca: relevador está dañado",
			"message": "Compresor no arranca - posible relevador dañado"
		},
		"RAD": {
			"eventCode": "RAD",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Compresor no arranca: relevador está dañado",
			"message": ""
		},
		"R04": {
			"eventCode": "R04",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Compresor no arranca: Compresor pegado o quemado",
			"message": "Compresor no arranca: posiblemente pegado o quemado"
		},
		"R59": {
			"eventCode": "R59",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Compresor no arranca: Compresor pegado o quemado",
			"message": "Compresor continúa sin arrancar: posiblemente pegado o quemado"
		},
		"RAE": {
			"eventCode": "RAE",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Compresor no arranca: Compresor pegado o quemado",
			"message": ""
		},
		"R05": {
			"eventCode": "R05",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Paro del compresor",
			"message": "Paro de compresor por un periodo de {value} minutos"
		},
		"R5A": {
			"eventCode": "R5A",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Paro del compresor",
			"message": ""
		},
		"RAF": {
			"eventCode": "RAF",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Paro del compresor",
			"message": ""
		},
		"R06": {
			"eventCode": "R06",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "No para el compresor",
			"message": "El compresor no para."
		},
		"R5B": {
			"eventCode": "R5B",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "No para el compresor",
			"message": "El compresor continúa sin parar"
		},
		"RB0": {
			"eventCode": "RB0",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "No para el compresor",
			"message": ""
		},
		"R07": {
			"eventCode": "R07",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Bajo nivel de refrigerante.",
			"message": "Bajo nivel de refrigerante"
		},
		"R5C": {
			"eventCode": "R5C",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Bajo nivel de refrigerante.",
			"message": "Bajo nivel de refrigerante"
		},
		"RB1": {
			"eventCode": "RB1",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Bajo nivel de refrigerante.",
			"message": "Bajo nivel de refrigerante por un lapso de {value} horas"
		},
		"R08": {
			"eventCode": "R08",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Tiempo de retardo de protección del compresor",
			"message": ""
		},
		"R5D": {
			"eventCode": "R5D",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Tiempo de retardo de protección del compresor",
			"message": "No se ha cumplido el tiempo de retardo de protección del compresor"
		},
		"RB2": {
			"eventCode": "RB2",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Tiempo de retardo de protección del compresor",
			"message": "No se ha cumplido el tiempo de retardo de protección del compresor por un periodo de {value} minutos"
		},
		"R09": {
			"eventCode": "R09",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 0,
			"label": "Retardo de activación de salida al arranque",
			"message": ""
		},
		"R5E": {
			"eventCode": "R5E",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 1,
			"label": "Retardo de activación de salida al arranque",
			"message": "No se cumplió retardo de activación de salida al arranque"
		},
		"RB3": {
			"eventCode": "RB3",
			"familyCode": "R",
			"family": "Compresor",
			"typeEvent": 2,
			"label": "Retardo de activación de salida al arranque",
			"message": "No se cumplió retardo de activación de salida al arranque por {value} segundos"
		},
		"S01": {
			"eventCode": "S01",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 0,
			"label": "Sonda 1 (cámara)",
			"message": ""
		},
		"S56": {
			"eventCode": "S56",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 1,
			"label": "Sonda 1 (cámara)",
			"message": "No se detecta sonda de la cámara"
		},
		"SAB": {
			"eventCode": "SAB",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 2,
			"label": "Sonda 1 (cámara)",
			"message": "No se ha detectado la sonda de la cámara por un periodo de {value} minutos"
		},
		"S02": {
			"eventCode": "S02",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 0,
			"label": "Sonda 2 (evaporador)",
			"message": ""
		},
		"S57": {
			"eventCode": "S57",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 1,
			"label": "Sonda 2 (evaporador)",
			"message": "No se detecta sonda del evaporador"
		},
		"SAC": {
			"eventCode": "SAC",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 2,
			"label": "Sonda 2 (evaporador)",
			"message": "No se ha detectado la sonda del evaporador por un periodo de {value} minutos"
		},
		"S03": {
			"eventCode": "S03",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 0,
			"label": "Sonda 3 (condensador)",
			"message": ""
		},
		"S58": {
			"eventCode": "S58",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 1,
			"label": "Sonda 3 (condensador)",
			"message": "No se detecta sonda del condensador"
		},
		"SAD": {
			"eventCode": "SAD",
			"familyCode": "S",
			"family": "Sonda",
			"typeEvent": 2,
			"label": "Sonda 3 (condensador)",
			"message": "No se ha detectado la sonda del condensador por un periodo de {value} minutos"
		},
		"T01": {
			"eventCode": "T01",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 0,
			"label": "Baja temperatura",
			"message": "Límite mínimo de temperatura excedido ({value}°C)."
		},
		"T56": {
			"eventCode": "T56",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 1,
			"label": "Baja temperatura",
			"message": "Límite mínimo de temperatura excedido ({value}°C)."
		},
		"TAB": {
			"eventCode": "TAB",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 2,
			"label": "Baja temperatura",
			"message": "El límite mínimo de temperatura se excedió durante {value} de segundos."
		},
		"T02": {
			"eventCode": "T02",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 0,
			"label": "Alta temperatura",
			"message": "Límite máximo de temperatura excedido ({value}°C)."
		},
		"T57": {
			"eventCode": "T57",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 1,
			"label": "Alta temperatura",
			"message": "Límite máximo de temperatura excedido ({value}°C)."
		},
		"TAC": {
			"eventCode": "TAC",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 2,
			"label": "Alta temperatura",
			"message": "El límite máximo de temperatura se excedió durante {value} de segundos."
		},
		"T03": {
			"eventCode": "T03",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 0,
			"label": "No abate temperatura",
			"message": "No se logró enfriar hasta {value}°C. en el tiempo máximo permitido. El compresor ha trabajado sin parar y de forma contínua."
		},
		"T58": {
			"eventCode": "T58",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 1,
			"label": "No abate temperatura",
			"message": "No se logró enfriar hasta {value}°C. en el tiempo máximo permitido. El compresor ha trabajado sin parar."
		},
		"TAD": {
			"eventCode": "TAD",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 2,
			"label": "No abate temperatura",
			"message": "Funcionamiento excesivo y sin parar del compresor. Durante {value} minutos no ha logrado enfriar hasta temperatura deseada."
		},
		"T04": {
			"eventCode": "T04",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 0,
			"label": "Modo ECO",
			"message": "ECO activado."
		},
		"T59": {
			"eventCode": "T59",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 1,
			"label": "Modo ECO",
			"message": "El equipo debió entrar en modo ECO y no lo ha hecho."
		},
		"TAE": {
			"eventCode": "TAE",
			"familyCode": "T",
			"family": "Temperatura",
			"typeEvent": 2,
			"label": "Modo ECO",
			"message": "Tiempo excesivo sin entrar en modo ECO ({value} horas sin activarse)."
		},
		"V01": {
			"eventCode": "V01",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 0,
			"label": "Bajo Voltaje",
			"message": "Bajo voltaje detectado (menor a {value} V c.a.)."
		},
		"V56": {
			"eventCode": "V56",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 1,
			"label": "Bajo Voltaje",
			"message": "Bajo voltaje (menor a {value} V c.a.)."
		},
		"VAB": {
			"eventCode": "VAB",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 2,
			"label": "Bajo Voltaje",
			"message": "Bajo voltaje por al menos {value} segundos."
		},
		"V02": {
			"eventCode": "V02",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 0,
			"label": "Alto Voltaje",
			"message": "Alto voltaje (mayor a {value} V c.a.)."
		},
		"V57": {
			"eventCode": "V57",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 1,
			"label": "Alto Voltaje",
			"message": "Alto voltaje (mayor a {value} V c.a.)."
		},
		"VAC": {
			"eventCode": "VAC",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 2,
			"label": "Alto Voltaje",
			"message": "Alto voltaje por al menos {value} segundos."
		},
		"V03": {
			"eventCode": "V03",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 0,
			"label": "Corte de suministro eléctrico",
			"message": ""
		},
		"V58": {
			"eventCode": "V58",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 1,
			"label": "Corte de suministro eléctrico",
			"message": "Falla eléctrica (voltaje menor a {value} V c.a.)."
		},
		"VAD": {
			"eventCode": "VAD",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 2,
			"label": "Corte de suministro eléctrico",
			"message": "Falla eléctrica por al menos {value} minutos."
		},
		"V04": {
			"eventCode": "V04",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 0,
			"label": "Picos de voltaje",
			"message": "Pico de voltaje de al menos {value} V c.a."
		},
		"V59": {
			"eventCode": "V59",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 1,
			"label": "Picos de voltaje",
			"message": "Pico de voltaje de al menos {value} V c.a."
		},
		"VAE": {
			"eventCode": "VAE",
			"familyCode": "V",
			"family": "Voltaje",
			"typeEvent": 2,
			"label": "Picos de voltaje",
			"message": "La duración del pico de voltaje fue de aprox. {value} segundos."
		},
		"X0A": {
			"eventCode": "X0A",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Compresor Voltaje",
			"message": "Autodiagnóstico finalizado: Compresor - OK. Voltaje en el compresor: {value} V"
		},
		"X0B": {
			"eventCode": "X0B",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Compresor Voltaje",
			"message": "Autodiagnóstico finalizado: Compresor - ERROR. Voltaje en el compresor: {value} V"
		},
		"X0C": {
			"eventCode": "X0C",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Compresor Corriente",
			"message": "Autodiagnóstico finalizado: Compresor - OK. Corriente en el compresor: {value} A"
		},
		"X0D": {
			"eventCode": "X0D",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Compresor Corriente",
			"message": "Autodiagnóstico finalizado: Compresor - ERROR. Corriente en el compresor: {value} A"
		},
		"X14": {
			"eventCode": "X14",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Ventilador del condensador Voltaje",
			"message": "Autodiagnóstico finalizado: Ventilador del condensador - OK. Voltaje en el ventilador del condensador: {value} V"
		},
		"X15": {
			"eventCode": "X15",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Ventilador del condensador Voltaje",
			"message": "Autodiagnóstico finalizado: Ventilador del condensador - ERROR. Voltaje en el ventilador del condensador: {value} V"
		},
		"X16": {
			"eventCode": "X16",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Ventilador del condensador Corriente",
			"message": "Autodiagnóstico finalizado: Ventilador del condensador - OK. Corriente en el ventilador del condensador: {value} A"
		},
		"X17": {
			"eventCode": "X17",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Ventilador del condensador Corriente",
			"message": "Autodiagnóstico finalizado: Ventilador del condensador - ERROR. Corriente en el ventilador del condensador: {value} A"
		},
		"X1E": {
			"eventCode": "X1E",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Ventilador del evaporador Voltaje",
			"message": "Autodiagnóstico finalizado: Ventilador del evaporador - OK. Voltaje en el ventilador del evaporador: {value} V"
		},
		"X1F": {
			"eventCode": "X1F",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Ventilador del evaporador Voltaje",
			"message": "Autodiagnóstico finalizado: Ventilador del evaporador - ERROR. Voltaje en el ventilador del evaporador: {value} V"
		},
		"X20": {
			"eventCode": "X20",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Ventilador del evaporador Corriente",
			"message": "Autodiagnóstico finalizado: Ventilador del evaporador - OK. Corriente en el ventilador del evaporador: {value} A"
		},
		"X21": {
			"eventCode": "X21",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Ventilador del evaporador Corriente",
			"message": "Autodiagnóstico finalizado: Ventilador del evaporador - ERROR. Corriente en el ventilador del evaporador: {value} A"
		},
		"X28": {
			"eventCode": "X28",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Elemento de deshielo Voltaje",
			"message": "Autodiagnóstico finalizado: Elemento de deshielo - OK. Voltaje en el elemento de deshielo: {value} V"
		},
		"X29": {
			"eventCode": "X29",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Elemento de deshielo Voltaje",
			"message": "Autodiagnóstico finalizado: Elemento de deshielo - ERROR. Voltaje en el elemento de deshielo: {value} V"
		},
		"X2A": {
			"eventCode": "X2A",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 3,
			"label": "Autodiagnóstico Elemento de deshielo Corriente",
			"message": "Autodiagnóstico finalizado: Elemento de deshielo - OK. Corriente en el elemento de deshielo: {value} A"
		},
		"X2B": {
			"eventCode": "X2B",
			"familyCode": "X",
			"family": "Sistema",
			"typeEvent": 4,
			"label": "Autodiagnóstico Elemento de deshielo Corriente",
			"message": "Autodiagnóstico finalizado: Elemento de deshielo - ERROR. Corriente en el elemento de deshielo: {value} A"
		}
	
	}

    constructor(
        private db: AngularFirestore,private storage:AngularFireStorage
    ) {
        
	}
	
	public getFirestoreInstance() : AngularFirestore {
		return this.db;
	}

	public getEventStructure() {
		return this.eventStructure;
	}

    public getOnChange(branch:string):Observable<any> {
        return new Observable((observer)=> {
            this.db.collection(branch)
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
                    items.push(item.payload.doc.data());
                });
                observer.next(items);
            });
        });
    }

    public getDocOnChange(collection,doc):Observable<any> {
        return new Observable((observer)=> {            
            this.db.collection(collection).doc(doc)
            .snapshotChanges()
            .subscribe((snapshot) => {
                if(snapshot)
                    observer.next(snapshot.payload.data());
            });
        });
	}
	
	public updateDocument(collection,docPath,doc):Promise<any> {
		return new Promise( (resolve, reject) => {
			this.db.collection(collection).doc(doc).update(doc).then((snapshot) => {
				resolve(snapshot);
			}).catch((error) => {
				reject(error);
			});
		});
	}
	
	public getDocument(collection,doc):Promise<any> {
        return new Promise((resolve,reject)=> {
			this.db.collection(collection).doc(doc)
			.get().toPromise().then((snapshot) => {
				if(snapshot)
				resolve(snapshot.data());
			}).catch((error) => {
				reject(error);
			});
        });
    }

	public getDocumentObs(collection,doc):Observable<any> {
        return  this.db.collection(collection).doc(doc)
			.get()
			.pipe(
				take(1),
				map( (docData: DocumentSnapshot<any>) => docData.data()),
				catchError( e => throwError(e))
			)
    }

    public getDocOnChangebyMac(collection,doc):Observable<any> {
        return new Observable((observer)=> { 
            this.db.collection(collection).doc(doc)
            .snapshotChanges()
            .subscribe((snapshot) => {
				if(snapshot){
					observer.next(snapshot.payload.data());
				}
            });
        });
	}
	
	public getEventsType0_2(branch:string,mac) : Observable<any> {
        return new Observable((observer)=> {
			this.db.collection(branch,ref => ref
				.where('typeEvent', '==', 0)
				.where('deviceId','==',mac)
				.orderBy('timestamp','desc')
				.limit(150)
				// .startAfter(null)
			)
            // this.db.collection(branch,ref => ref.where('typeEvent', '==', 0).orderBy('timestamp','desc'))
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);      
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					}
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

	public getEventsType1_2(branch:string,mac):Observable<any> {
        return new Observable((observer)=> {            
			this.db.collection(branch,ref => ref
				.where('typeEvent', '==', 1)
				.where('deviceId','==',mac)
				.limit(150)
			)
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);  
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					} 
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

	public getEventsType2_2(branch:string,mac):Observable<any> {
        return new Observable((observer)=> {            
			this.db.collection(branch,ref => ref
				.where('typeEvent', '==', 2)
				.where('deviceId','==',mac)
				.limit(150)
			)
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);  
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					} 
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

	public getEventsType0(branch:string,mac):Observable<any> {
        return new Observable((observer)=> {            
			this.db.collection(branch,ref => ref.where('typeEvent', '==', 0).where('deviceId','==',mac).orderBy('timestamp','desc'))
            // this.db.collection(branch,ref => ref.where('typeEvent', '==', 0).orderBy('timestamp','desc'))
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);      
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					}
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

    public getEventsType1(branch:string,mac):Observable<any> {
        return new Observable((observer)=> {            
            this.db.collection(branch,ref => ref.where('typeEvent', '==', 1).where('deviceId','==',mac))
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);  
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					} 
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

	public getEventsType2(branch:string,mac):Observable<any> {
        return new Observable((observer)=> {            
            this.db.collection(branch,ref => ref.where('typeEvent', '==', 2).where('deviceId','==',mac))
            .snapshotChanges()
            .subscribe((snapshot) => {
                let items = [];
                snapshot.forEach((item) => {
					this.alerta = item.payload.doc.data();
					if(this.eventStructure[this.alerta['eventCode']]){
						let aux = this.eventStructure[this.alerta['eventCode']];
						this.alerta['message'] = String(aux.message).replace('{value}', this.alerta['value']);  
						this.alerta['label'] = String(aux.label).replace('{value}', this.alerta['value']);                    
						this.alerta['family'] = String(aux.family).replace('{value}', this.alerta['value']);
					} 
					if(this.alerta['message']){
						items.push(this.alerta);
					}
                });
                observer.next(items);
            });
        });
	}

	public getLimits(collection,doc):Observable<any> {
        return new Observable((observer)=> { 
            this.db.collection(collection).doc(doc)
            .snapshotChanges()
            .subscribe((snapshot) => {
				if(snapshot){
					observer.next(snapshot.payload.data());
				}
            });
        });
	}

    public getFileUrl(path): Promise<any> {
        return new Promise((resolve, reject) => {
            let ref = this.storage.ref(path);
            ref.getDownloadURL().toPromise().then(url => {
                resolve(url);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        }).catch(error => {
            
        });
	}
	
    public uploadImage(path, imageFile): Promise<any> {
        return new Promise((resolve, reject) => {
            const ref = this.storage.ref(path);
            const task = ref.put(imageFile).then((res)=>{
                resolve(res);
            }).catch((error)=>{
                reject(error);
            })
        });
	}
	
	public addDocumentToCollection( collection : string, data : any ) : Promise<any> {
		let payload = Object.assign({},data);

		if( payload.date ) {
			let time = moment(payload.date)
			let date = new Date(time.toDate());
			payload.date = date;
		}

		if( payload.startDate ) {
			let time = moment(payload.startDate)
			let date = new Date(time.toDate());
			payload.startDate = date;
		}
		
		return this.db.collection(collection).add(payload);
	}

}