import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PrivilegesService } from './privileges.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PrivilegesGuard implements CanLoad, CanActivate {

    constructor( private router: Router, public privilegesService: PrivilegesService ) { }

    async canLoad( route: Route ) {
        const view = (route.data && route.data.view) ? route.data.view : '';
        const event = (route.data && route.data.event) ? route.data.event : '';
        let data;
        await this.privilegesService.viewsEvents.then( prom => data = prom );
        // console.log('🍎' , 'canLoad');

        if ( view && view !== '' && data !== null && data.views && data.views.length > 0 && data.views.includes(view) === true ) {
            return true;
        }
        if ( event && event !== '' && data !== null && data.events && data.events.length > 0 && data.events.includes(event) === true ) {
            return true;
        }
        this.privilegesService.message = 'Requieres de nuevos poderes para acceder a este recurso 🙅 ...';
        this.router.navigate(['/']);
        return false;
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        const view = (route.data && route.data.view) ? route.data.view : '';
        const event = (route.data && route.data.event) ? route.data.event : '';
        let data;
        await this.privilegesService.viewsEvents.then( prom => data = prom );
        // console.log('🤡' , 'canActivate');

        if ( view && view !== '' && data !== null && data.views && data.views.length > 0 && data.views.includes(view) === true ) {
            this.privilegesService.setItem( 'prevPageSlider', state.url);
            return true;
        }
        if ( event && event !== '' && data !== null && data.events && data.events.length > 0 && data.events.includes(event) === true ) {
            this.privilegesService.setItem( 'prevPageSlider', state.url);
            return true;
        }
        const title = (route.data && route.data.title) ? route.data.title : '';
        const pagePrev = this.privilegesService.getItem('prevPageSlider', false);
        this.privilegesService.message = `Requieres de nuevos poderes para acceder a este recurso 🙅 ... ${ title !== '' ? (' <br> Intentando acceso a: ' + title) : '' }`;

        this.router.navigate([ pagePrev ? pagePrev : '/']);
        return false;
    }

}
