import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { IPrivilegeUser, IModules, IRoles, IPrivilegesSystem, IEnterprises, IUserData } from '../interfaces/privileges.interface';

@Injectable({
    providedIn: 'root'
})
export class PrivilegesService {

    public messageUser: BehaviorSubject<string>;
    public messageUser$: Observable<any>;
    public authorizationUser: BehaviorSubject<any>;
    public authorizationUser$: Observable<any>;

    public tz: BehaviorSubject<any>;
    public tz$: Observable<any>;

    constructor( private afStoreDB: AngularFirestore ) {
        this.messageUser = new BehaviorSubject<string>('');
        this.messageUser$ = this.messageUser.asObservable();
        this.authorizationUser = new BehaviorSubject<any>(null);
        this.authorizationUser$ = this.authorizationUser.asObservable().pipe(
            map( data => <{views: [any], events: [any] }>JSON.parse( atob(data) ) )
        );
        this.tz = new BehaviorSubject<string>('');
        this.tz$ = this.tz.asObservable();
    }

    set timezone(tz: string) {
        this.tz.next( tz);
    }
    get timezone(): string {
        return this.tz.value;
    }

    set message( text ) {
        this.messageUser.next( text );
    }
    get message(): string {
        return this.messageUser.value;
    }

    set viewsEventsData( data ) {
        this.authorizationUser.next(
            btoa(JSON.stringify(data))
        );
    }

    get viewsEvents(): Promise<any> {
        return new Promise( (resolve, reject) => {
            if (this.authorizationUser.value === null ) {
                const userData: IUserData = this.getItem('userDataSlider');
                if (userData && userData.enterpriseId && userData.id) {
                    this.getModulesInit( userData.enterpriseId ).pipe( take(1) ).subscribe( privilegesModules => {
                        this.getUserPrivileges( userData.enterpriseId, userData.id ).pipe( take(1) ).subscribe( privileges => {
                            const modulesView = [];
                            this.loadMenu(privileges, privilegesModules.modules, privilegesModules.roles, modulesView);
                            if (this.authorizationUser.value === null ) {
                                resolve(this.authorizationUser.value);
                            } else {
                                resolve(<{views: [any], events: [any] }>JSON.parse( atob(this.authorizationUser.value) ));
                            }
                        }, () => reject(false));
                    }, () => reject(false));
                }
            } else {
                resolve(<{views: [any], events: [any] }>JSON.parse( atob(this.authorizationUser.value) ));
            }
        });
    }

    public getDataEnterprises(enterpriseId: string): Observable<IEnterprises> {
        return this.afStoreDB.collection(`enterprises`).doc(enterpriseId)
        .snapshotChanges()
        .pipe(
            map( snapshot => snapshot.payload.data()),
            map( (data: any) => {
                if (!data.images) {
                    data.images = { logo: '', logoShort: '' };
                }
                if (!data.images.logo || data.images.logo === '' ) {
                    data.images.logo = 'logo.png';
                }
                if (!data.images.logoShort || data.images.logoShort === '' ) {
                    data.images.logoShort = 'logo_short.png';
                }
                return data;
            }),
            map( ({ images }: any) => <IEnterprises>({ logo: images.logo, logoShort: images.logoShort }) )
        );
    }

    public getModulesInit(enterpriseId: string): Observable<IPrivilegesSystem> {
        return this.afStoreDB.collection(`enterprises`).doc(enterpriseId)
        .snapshotChanges()
        .pipe(
            map( snapshot => snapshot.payload.data()),
            map( ({ privileges }: any) => <IPrivilegesSystem>({ modules: privileges.modules, roles: privileges.roles }) )
        );
    }

    public getUserPrivileges(enterpriseId: string, idUser: string): Observable<IPrivilegeUser> {
        return this.afStoreDB.collection(`enterprises`).doc(enterpriseId).collection(`users`).doc(idUser)
        .snapshotChanges()
        .pipe(
            map( snapshot => snapshot.payload.data()),
            map( ({ privileges }: any) => <IPrivilegeUser>({
                modules: (privileges && privileges.modules) ? privileges.modules : {},
                views: (privileges && privileges.views) ? privileges.views : {},
                events: (privileges && privileges.events) ? privileges.events : {},
                roles: (privileges && privileges.roles) ? privileges.roles : {} }) )
        );
    }

    loadMenu( privilegesUser: IPrivilegeUser, modulesSystem: IModules, roles: IRoles, modulesView: any[] ) {
        const roleAdmin = 'ROLE_ADMIN';
        let isUserAdmin = false;
        // console.log('🌞', '🌞', '🌞', '🌞', '🌞');

        let moduleUser = {}, viewsUser = {}, eventsUser = {};
        if (privilegesUser.hasOwnProperty('modules')) {
            moduleUser = privilegesUser['modules'];
        }
        if (privilegesUser.hasOwnProperty('views')) {
            viewsUser = privilegesUser['views'];
        }
        if (privilegesUser.hasOwnProperty('events')) {
            eventsUser = privilegesUser['events'];
        }
        if (privilegesUser['roles']) {
            isUserAdmin = (privilegesUser['roles'][roleAdmin] !== undefined && privilegesUser['roles'][roleAdmin].isActive === true) ? true : false;
            if (isUserAdmin === false) {
                for (const role in privilegesUser['roles']) {
                    if (privilegesUser['roles'].hasOwnProperty(role)) {
                        const element = privilegesUser['roles'][role];
                        if (element.hasOwnProperty('isActive') && roles[role] && roles[role].isActive === true && element.isActive === true) {
                            // SE AGREGAN LOS MODULOS DE LOS ROLES
                            const roleModules = roles[role].modules;
                            this.newListObjecValid(roleModules, moduleUser);
                            // SE AGREGAN LAS VISTAS DE LOS ROLES
                            const roleViews = roles[role].views;
                            this.newListObjecValid(roleViews, viewsUser);
                            // SE AGREGAN LOS EVENTOS DE LOS ROLES
                            const roleEvents = roles[role].events;
                            this.newListObjecValid(roleEvents, eventsUser);
                        }
                    }
                }
            }
        }
        const modules = [], views = [], events = [];
        if (isUserAdmin === false) {
            this.newObjectToArray(moduleUser, modules);
            this.newObjectToArray(viewsUser, views);
            this.newObjectToArray(eventsUser, events);
        }

        const allViews = [], allEvents = [];
        for (const key in modulesSystem) {
            if (modulesSystem.hasOwnProperty(key) && modulesSystem[key].isActive === true && (isUserAdmin === true || modules.find(x => x === key) !== undefined)) {
                // SE AGREGAN LAS OPCIONES DEL MENÚ PARA LAS VISTAS
                const viewsModule = modulesSystem[key].views;
                let listViews = [];
                if (viewsModule) {
                    for (const view in viewsModule) {
                        if (viewsModule.hasOwnProperty(view) && viewsModule[view].isActive === true && (isUserAdmin === true || views.find(x => x === view) !== undefined)) {
                            listViews.push({
                                title: viewsModule[view].title,
                                routerLink: viewsModule[view].routerLink ? viewsModule[view].routerLink : undefined,
                                order: viewsModule[view].order ? viewsModule[view].order : 0
                            });
                            allViews.push(view);
                        }
                    }
                }
                listViews = listViews.sort((a, b) => {
                    if (a.order < b.order) { return -1; }
                    if (a.order > b.order) { return 1; }
                    return 0;
                });
                // SE AGREGAN LAS OPCIONES DEL MENÚ PARA LOS EVENTSO
                const eventsModule = modulesSystem[key].events;
                if (eventsModule) {
                    for (const event in eventsModule) {
                        if (eventsModule.hasOwnProperty(event) && eventsModule[event].isActive === true && (isUserAdmin === true || events.find(x => x === event) !== undefined)) {
                            allEvents.push(event);
                        }
                    }
                }
                // SE AGREGA EL MÓDULO PARA QUE EL USUSARIO LO VEA
                modulesView.push({
                    key,
                    title: modulesSystem[key].title,
                    order: modulesSystem[key].order,
                    icon: modulesSystem[key].icon ? modulesSystem[key].icon : undefined,
                    iconClass: modulesSystem[key].iconClass ? modulesSystem[key].iconClass : undefined,
                    listViews
                });
            }
        }

        // this.viewsEvents = {views: allViews, events: allEvents };
        this.viewsEventsData = {views: allViews, events: allEvents };

        modulesView = modulesView.sort((a, b) => {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
        });
    }
    newListObjecValid(objOrigin, objNew) {
        if (objOrigin !== undefined) {
            for (const obj in objOrigin) {
                if (objOrigin.hasOwnProperty(obj)) {
                    if (!objNew[obj]) {
                        objNew[obj] = objOrigin[obj];
                    }
                }
            }
        }
    }
    newObjectToArray(object, array) {
        if (Object.keys(object).length > 0) {
            for (const obj in object) {
                if (object.hasOwnProperty(obj)) {
                    const element = object[obj];
                    if (element.hasOwnProperty('isActive') && element.isActive === true) {
                        array.push(obj);
                    }
                }
            }
        }
    }



    // :::::::::::::::::::::::::::::::::::::::::::::::::::::

    public setItem( key: string, value: string) {
        sessionStorage.setItem(key, btoa(value));
    }

    public getItem(key: string, json: boolean = true) {
        if (sessionStorage.getItem(key) !== null ) {
            return json === true ? JSON.parse( atob(sessionStorage.getItem(key)) ) : atob(sessionStorage.getItem(key));
        } else {
            return undefined;
        }
    }

    public set removeStorage( item: string ) {
        if (sessionStorage.getItem(item) !== null ) {
            sessionStorage.removeItem( item );
        }
    }
}
