import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class GuardService implements CanActivate {
    constructor(
        private router : Router,
        private auth : AuthService
    ) {

    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let session = await this.validSession();
        let module = state.url.split('/')[1];

        if(module == 'authentication'){
            if(session)
                this.router.navigate(['/']);
            else
                return true;
        }

        if(!session)
            this.router.navigate(['/authentication/login']);

        return session;
    }

    public validSession():Promise<any>{
        return new Promise((resolve,reject)=>{
            try{
                let validate = this.auth.isLoggedIn().subscribe((session)=>{
                    try{
                        validate.unsubscribe();
                        resolve(session.emailVerified == false ? false : true)
                    }catch(error){
                        resolve(false);
                    }
                });
            }catch(error) {                
                reject(error);
            }
        });
    }
}
