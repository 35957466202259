export const components = [
    {
        name:"voltage",
        type:"voltage",
        label:"Voltaje",
        measure:"V"
    },
    {
        name:"current",
        type:"current",
        label:"Corriente",
        measure:"A"
    },
    {
        name:"temp_internal_s1",
        type:"temperature",
        label:"Temperatura interior S1",
        measure:"ºC"
    },
    {
        name:"energy_consumption",
        type:"energyConsumption",
        label:"Consumo de energía",
        measure:"ºC"
    },
    {
        name:"compressor",
        type:"compressor",
        label:"Compresor",
        measure:"switch"
    },
    {
        name:"evaporator",
        type:"evaporator",
        label:"Ventilador del evaporador",
        measure:"switch"
    },
    {
        name:"resistance",
        type:"thaw",
        label:"Resistencia de deshielo",
        measure:"switch"
    },
    {
        name:"door",
        type:"door",
        label:"Puerta",
        measure:"door"
    },
    {
        name:"light_door",
        type:"illumination",
        label:"Iluminación puerta",
        measure:"%"
    },
    {
        name:"frequency",
        type:"frequency",
        label:"Frecuencia",
        measure:"Hz"
    },
    {
        name:"period",
        type:"period",
        label:"Periodo",
        measure:"ms"
    },
    {
        name:"active_power",
        type:"activePower",
        label:"Potencia activa",
        measure:"W"
    },
    {
        name:"reactive_power",
        type:"reactivePower",
        label:"Potencia reactiva",
        measure:"VAr"
    },
    {
        name:"apparent_power",
        type:"apparentPower",
        label:"Potencia aparente",
        measure:"VA"
    },
    {
        name:"reactive_energy",
        type:"reactiveEnergy",
        label:"Energía reactiva",
        measure:"VArh"
    },	
    {
        name:"apparent_energy",
        type:"apparentEnergy",
        label:"Energía aparente",
        measure:"VAh"
    },
    {
        name:"active_energy",
        type:"activeEnergy",
        label:"Energía activa",
        measure:"Wh"
    },
    {
        name:"power_factor",
        type:"powerFactor",
        label:"Factor de potencia",
        measure:""
    },
    {
        name:"fasorial_angle",
        type:"phasorAngle",
        label:"Ángulo fasorial",
        measure:"º"
    },
    {
        name:"reactive_energy_daily",
        type:"reactiveEnergyDay",
        label:"Energía reactiva del día",
        measure:"kVAr día"
    },
    {
        name:"apparent_energy_daily",
        type:"apparentEnergyDay",
        label:"Energía aparente del día",
        measure:"kVA día"
    },
    {
        name:"active_energy_daily",
        type:"activeEnergyDay",
        label:"Energía activa del día",
        measure:"kW día"
    },
    {
        name:"reactive_energy_monthly",
        type:"reactiveEnergyMonth",
        label:"Energía reactiva del mes",
        measure:"kVAr mes"
    },
    {
        name:"apparent_energy_monthly",
        type:"apparentEnergyMonth",
        label:"Energía aparente del mes",
        measure:"kVA mes"
    },
    {
        name:"active_energy_monthly",
        type:"activeEnergyMonth",
        label:"Energía activa del mes",
        measure:"kW mes"
    },
    {
        name:"reactive_energy_bimonthly",
        type:"reactiveEnergyBimester",
        label:"Energía reactiva del bimestre",
        measure:"kVAr bimestre"
    },
    {
        name:"apparent_energy_bimonthly",
        type:"apparentEnergyBimester",
        label:"Energía aparente del bimestre",
        measure:"kVA bimestre"
    },
    {
        name:"active_energy_bimonthly",
        type:"activeEnergyBimester",
        label:"Energía activa del bimestre",
        measure:"kW bimestre"
    },
    {
        name:"reactive_energy_annually",
        type:"reactiveEnergyYear",
        label:"Energía reactiva del año",
        measure:"kVAr año"
    },
    {
        name:"apparent_energy_annually",
        type:"apparentEnergyYear",
        label:"Energía aparente del año",
        measure:"kVA año"
    },
    {
        name:"active_energy_annually",
        type:"activeEnergyYear",
        label:"Energía activa del año",
        measure:"kW año"
    },
    {
        name:"total_reactive_energy",
        type:"reactiveEnergyTotal",
        label:"Energía reactiva total",
        measure:"kVAr total"
    },
    {
        name:"total_apparent_energy",
        type:"apparentEnergyTotal",
        label:"Energía aparente total",
        measure:"kVA total"
    },
    {
        name:"total_active_energy",
        type:"activeEnergyTotal",
        label:"Energía activa total",
        measure:"kW total"
    }
];