import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GuardService } from './shared/services/guard.service';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { PrivilegesGuard } from './shared/services/privileges.guard';

export const routes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: 'homepage',
                loadChildren: './vikkon/homepage/homepage.module#HomePageModule',
                canActivate: [GuardService]
            },
            {
                path: 'inventory',
                loadChildren: './vikkon/actives/inventory/inventory.module#InventoryModule',
                canActivate: [GuardService],
            },
            {
                path: 'layouts',
                loadChildren: './vikkon/data-layouts/data-layouts.module#DataLayoutsModule',
                canActivate: [GuardService]
            },
            {
                path: 'users',
                loadChildren: './vikkon/users/users.module#UsersModule',
                canActivate: [GuardService]
            },
            {
                path: 'technical-support',
                loadChildren: './vikkon/technical-support/technical-support.module#TechnicalSupportModule',
                canActivate: [GuardService]
            },
            {
                path: 'contacts',
                loadChildren: './vikkon/contacts/contacts.module#ContactsModule',
                canActivate: [GuardService]
            },
            {
                path: 'devices',
                loadChildren: './vikkon/actives/devices/devices.module#DevicesModule',
                canActivate: [GuardService]
            },
            {
                path: 'health',
                loadChildren: './vikkon/actives/health/health.module#HealthModule',
                canActivate: [GuardService]
            },
            {
                path: 'maps',
                loadChildren: './vikkon/actives/maps/maps.module#MapsModule',
                canActivate: [GuardService]
            },            
            {
                path: 'reports/assets',
                loadChildren: './vikkon/actives/active-report/active-report.module#ActiveReportModule',
                canActivate: [GuardService]
            },
            {
                path: 'link',
                loadChildren: './vikkon/energy/adafruit/adafruit.module#AdaFruitModule',
                canActivate: [GuardService]
            },
            {
                path: 'led',
                loadChildren: './vikkon/sales/vikkon-led/vikkon-led.module#vikkkonLedModule',
                canActivate: [GuardService]
            },
            {
                path: 'catalogues',
                loadChildren: './vikkon/catalogues/catalogues.module#CataloguesModule',
                canActivate: [GuardService]
            },
            {
                path: 'final-provision',
                loadChildren: './vikkon/final-provision/final-provision.module#FinalProvisionModule',
                canActivate: [GuardService]
            },
            {
                path: 'variables',
                loadChildren: './vikkon/actives/variables/variables.module#VariablesModule',
                canActivate: [GuardService]
            },
            {
                path: 'iot',
                loadChildren: './vikkon/actives/iot/iot.module#IotModule',
                canActivate: [GuardService]
            },
            {
                path: 'variables-measured-by-devices',
                loadChildren: './vikkon/actives/variables-measured-by-devices/variables-measured-by-devices.module#VariablesMeasuredByDevicesModule',
                canActivate: [GuardService]
            },
            {
                path: 'report',
                loadChildren: './vikkon/reports/reports.module#ReportsModule',
                canActivate: [GuardService]
            },
            {
                path: '',
                redirectTo: 'homepage',
                pathMatch: 'full'
            },
            {
                path: 'tools-admin',
                loadChildren: './vikkon/tools-administrative/tools-administrative.module#ToolsAdministrativeModule',
                canActivate: [GuardService]
            }
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: './authentication/authentication.module#AuthenticationModule',
                canActivate: [GuardService]
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
    exports: [RouterModule]
})
export class AppRoutingModule { }
