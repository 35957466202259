import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    public changeLang = new BehaviorSubject<string>('init');
    public changeLang$: Observable<string> = this.changeLang.asObservable();

    constructor() { }

    set changeLanguage(lang: string) {
        this.changeLang.next(lang);
    }

    get changeLanguage(): string {
        return this.changeLang.value;
    }
}
