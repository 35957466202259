import { Injectable } from '@angular/core';
import { FirebaseService } from './../../../shared/services/firebase.service';
import { RequestService } from '../../../shared/services/request.service';
import { AuthService } from '../../../shared/services/auth.service';
import { from, Observable, throwError } from 'rxjs';

import { map, tap, catchError, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Http, } from '@angular/http';
import * as moment from 'moment';
import { RequestOptions } from './../../../shared/interfaces/requests.interface'

@Injectable()
export class DevicesService {

    constructor(
        private firebase: FirebaseService,
        private request: RequestService,
        private auth: AuthService,
        private afDB: AngularFirestore,
        private http: Http,

    ) { }

    getEnterprises(): Observable<any> {
        return this.firebase.getOnChange('enterprises');
    }

    getEnterprise(uid: string): Observable<any> {
        return this.afDB.collection(`enterprises`).doc(uid)
            .snapshotChanges()
            .pipe(
                map(docData => {
                    const data = docData.payload.data();
                    return { data };
                })
            );
    }

    getUsers(): Observable<any> {
        return new Observable((obs) => {
            this.firebase.getOnChange('users').subscribe((users) => {
                obs.next(users);
            })
        });
    }

    register(newItem): Observable<any> {
        delete newItem.isActive;
        const promise = new Promise((resolve, reject) => {
            this.request.post('refrigerators/register', newItem, 'production', 30000).then((data) => {
                resolve(data);
            }).catch((error) => reject(error));
        });

        return from(promise).pipe(
            take(1),
            map(resp => resp),
            catchError(e => throwError(e))
        );
    }

    activateRegister(newItem): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            this.request.post('devices/activate', newItem, 'production', 30000).then((data) => {
                resolve(data);
            }).catch((error) => reject(error));
        });

        return from(promise).pipe(
            take(1),
            map(resp => resp),
            catchError(e => throwError(e))
        );
    }

    getPictureURL(path) {
        return this.firebase.getFileUrl(path);
    }

    public getAllRefrigerators(page, limit, sortBy, filter?): Promise<any> {
        return new Promise((resolve, reject) => {
            let link = `refrigerators?page=${page}&limit=${limit}&sortBy=${sortBy}:desc,model:asc`;
            if (filter) {
                link += `&filter=${filter}`;
            }
            this.request.get(link, null, 'production', 30000)
                .then((data) => {
                    console.log(data);

                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    uploadPicture(picture, name) {
        return this.firebase.uploadImage(picture, name);
    }

    // public getOutlets() {
    //     return new Promise((resolve, reject) => {
    //         this.request.get(`outlets/active-list`, null, 'outlets', 30000).then((data) => {
    //             resolve(data);
    //         }).catch((error) => {
    //             reject(error);
    //         });
    //     });
    // }

    public getAllOutlets(options: RequestOptions, customerId: Number) {
        const filter = options.filter ? `&filter=${options.filter}` : ''
        const sorting = options.sortBy ? `&sortBy=${options.sortBy}:${options.order || 'asc'}` : ''
        const pagination = `page=${options.page || 1}&limit=${options.limit || 10}`
        const url = `outlets?customerId=${ customerId }&${pagination}${filter}${sorting}`
        return this.request.get(url, null, 'outlets', 30000)
    }

    getOutletByCode(code: number): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            this.request.get(`outlets/code/${ code }`, null, 'outlets', 30000)
            .then( data => resolve(data ))
            .catch( error => reject(error))
        });
        return from(promise).pipe(
            take(1),
            catchError( e => throwError(e))
        );
    }

}

