import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { reject } from 'q';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AuthService {

    private errorCodes: any = {
        "auth/invalid-password" : "La contraseña proporcionada debe ser de al menos 6 caracteres.",
        "auth/email-already-exists" : "La cuenta de correo proporcionada ya se encuentra en uso por otra cuenta.",
        "auth/email-already-in-use" : "La cuenta de correo proporcionada ya se encuentra en uso.",
        "auth/invalid-email" : "Proporcione una cuenta de correo válida",
        "auth/user-not-found" : "No existe una cuenta de usuario con el correo electrónico proporcionado",
        "auth/wrong-password" : "La contraseña proporcionada es incorrecta",
        "auth/too-many-requests" : "Demasiados intentos de inicio de sesión fallidos. Por favor, inténtelo de nuevo más tarde.",
        "auth/argument-error" : "Información inválida por favor verifiquela nuevamente",
        "auth/invalid-action-code" : "Acción denegada asegurese de estar utilizando un link de activación vigente, si el error persiste solicite de nuevo el correo de activación",
        "auth/user-disabled" : "La cuenta de usuario se encuentra deshabilitada",
        "auth/phone-number-already-exists" : "El teléfono proporcionado ya se encuentra en uso por otro usuario."
    };

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private db: AngularFirestore
    ) {

    }

    public signUp(user):Promise<any>{
        return new Promise((resolve,reject)=>{
            this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password).then((result) => {
                this.sendEmailVerification(result);
                this.updateProfile({displayName:user.name}).then(() => {
                    this.afAuth.authState.subscribe((session) => {
                        resolve(result);
                    })
                }).catch((error) => {
                    reject(error);
                })
            }).catch((error)=>{
                reject(this.errorCodes[error.code]);
            })
        });
    }

    public logIn(username,password):Promise<any> {
        return new Promise((resolve,reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(username, password).then((session:any)=>{
                if(session){
                    if(session.user.emailVerified){
                        resolve(session);
                    }else{
                        this.logOut();
                    }
                    reject("Aún no has verificado tu cuenta, revisa tu buzón de correo electrónico");
                }
                reject("No se pudo iniciar la sesión, inténtelo nuevamente")
            }).catch((error)=>{
                reject(this.errorCodes[error.code]);
            })
        });
    }

    public logOut():Promise<any>{
        return this.afAuth.auth.signOut()
    }

    public updateProfile(profile):Promise<any>{
        let data:any = {};
        if(profile.displayName)
            data.displayName = profile.displayName;
        return this.afAuth.auth.currentUser.updateProfile(data);
    }

    public sendEmailVerification(session) {
        return session.user.sendEmailVerification();
    }

    public recoverPassword(email):Promise<any>{
        return new Promise((resolve,reject) => {
            return this.afAuth.auth.sendPasswordResetEmail(email).then((result)=>{
                resolve(result);
            }).catch((error)=>{
                reject(this.errorCodes[error.code]);
            })
        })
    }

    public parseError(code) {
        return this.errorCodes[code];
    }

    public onSignIn():Observable<any> {
        return new Observable(observer => {
            this.afAuth.authState.subscribe((sign) => {
                observer.next(sign);
            });
        });
    }

    public deleteFirebaseInstance() {
        localStorage.clear()
        this.afAuth.authState.subscribe((sign) => {
            this.router.navigateByUrl("login");
        });
    }

    public isLoggedIn():Observable<any> {
        return new Observable((observer)=>{
            this.afAuth.authState.subscribe((session) => {
                observer.next(session);
            });
        });
    }

    public getToken(){
        return this.afAuth.auth.currentUser.getIdToken();
    }

    public getTokenOnChanged():Observable<string | null> {
        return this.afAuth.idToken
    }

    public getUserId(){
        return this.afAuth.auth.currentUser.uid;
    }

    public getUserData():Observable<any>{
        return new Observable((observer) => {
            if(this.afAuth.auth){
                this.db.collection(`users`).doc(this.afAuth.auth.currentUser.uid)
                .snapshotChanges()
                .subscribe((snapshot) => {
                    observer.next(snapshot.payload.data());
                });
            }else{
                observer.error("no se ha podido obtener el id del usuario");
            }
        });
    }
}
