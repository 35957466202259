import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { Router } from '@angular/router';
import { InventoryService } from './../../vikkon/actives/inventory/inventory.service';
import { FirebaseService } from './../services/firebase.service';
import { PrivilegesService } from '../services/privileges.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../services/i18n.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.style.css'],
    providers: [InventoryService, FirebaseService]
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
    customerName = '';
    name: string;
    showHide: boolean;
    user: any = {};
    customers: any = [];

    private enterprise$: Subscription;
    private user$: Subscription;

    public enterprise: any;
    public showMobileMenu = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private invService: InventoryService,
        public privilegesService: PrivilegesService,
        public translateService: TranslateService,
        public i18nService: I18nService
    ) {
        this.showHide = true;
        this.getProfile();
        this.customerName = localStorage.getItem('customerName');
        this.invService.getActiveCustomers().then((customers: any) => {
            this.customers = customers;
            if (this.customerName == null) {
                this.updateCustomer(customers[0].id, customers[0].firstName, customers[0].logoName);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.enterprise$) {
            this.enterprise$.unsubscribe();
        }
        if (this.user$) {
            this.user$.unsubscribe();
        }
    }

    updateCustomer(id, name, logo) {
        localStorage.setItem('customerId', id);
        localStorage.setItem('customerName', name);
        localStorage.setItem('customerLogo', logo);
        this.customerName = name;
    }

    changeShowStatus() {
        this.showHide = !this.showHide;
    }

    ngAfterViewInit() {
        $(function () {
            $('.preloader').fadeOut();
        });

        const set = function () {
            const width =
                window.innerWidth > 0 ? window.innerWidth : this.screen.width;
            const topOffset = 70;
            if (width < 1170) {
                $('body').addClass('mini-sidebar');
                $('.navbar-brand span').hide();
                $('.scroll-sidebar, .slimScrollDiv')
                    .css('overflow-x', 'visible')
                    .parent()
                    .css('overflow', 'visible');
                $('.sidebartoggler i').addClass('ti-menu');


                this.showMobileMenu = !this.showMobileMenu;

            } else {
                $('body').removeClass('mini-sidebar');
                $('.navbar-brand span').show();
                $('.sidebartoggler i').removeClass('ti-menu');
            }

            const height =
                (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;

            if (height > topOffset) {
                $('.page-wrapper').css('min-height', height + 'px');
            }
        };
        $(window).ready(set);
        $(window).on('resize', set);

        $(document).on('click', '.mega-dropdown', function (e) {
            e.stopPropagation();
        });

        $('.search-box a, .search-box .app-search .srh-btn').on(
            'click',
            function () {
                $('.app-search').toggle(200);
            }
        );

        (<any>$('[data-toggle="tooltip"]')).tooltip();

        (<any>$('.scroll-sidebar')).slimScroll({
            position: 'left',
            size: '5px',
            height: '100%',
            color: '#dcdcdc'
        });

        (<any>$('.right-sidebar')).slimScroll({
            height: '100%',
            position: 'right',
            size: '5px',
            color: '#dcdcdc'
        });

        (<any>$('.message-center')).slimScroll({
            position: 'right',
            size: '5px',
            color: '#dcdcdc'
        });

        $('body').trigger('resize');
    }

    getProfile() {
        this.user$ = this.auth.getUserData().subscribe((data) => {
            data.firstName = data.firstName.split(' ')[0];
            data.lastName = data.lastName.split(' ')[0];
            data.email = (
                data.email.split('@')[0]).substring(0, 10) + (data.email.split('@')[0].length >= 10 ? '...@' : '@') + data.email.split('@')[1];
            this.user = data;

            this.enterprise$ = this.privilegesService.getDataEnterprises(data.enterpriseId).subscribe(enterprise => {
                this.enterprise = enterprise;
            });
        });
    }

    logOut() {
        this.auth.logOut().then(() => {
            this.router.navigate(['/authentication/login']);
        });
    }

    setCustumerId(customerId, customerName, customerLogo) {
        this.customerName = customerName;
        this.updateCustomer(customerId, customerName, customerLogo);
        this.invService.setCustomerId(customerId);

        if ( this.router.url === '/homepage') {
            location.reload();
        } else {
            this.router.navigate(['/homepage']);
        }
    }

    changeLanguage(lang: string) {
        this.translateService.use(lang).subscribe( _ => {
            this.i18nService.changeLanguage = lang;
        });
    }
}
